import React, { Component } from "react"; 
import {Link} from "react-router-dom";
import axios from "axios";
import Spinner from "../../common/Spinner";

class SectionsTable extends Component {
	constructor(props){
		super(props);
		this.state = {
			errors: {},
			data: [],
		}

		this.deleteData = this.deleteData.bind(this);
	}

	deleteData(e){
		if(!window.confirm('Are you sure?  This cannot be undone!')) {
			return;
		}

		let val = e.target.getAttribute('value');
		let arr;
		if(this.state.data.length === 0)
		{
			this.setState({data: this.props.data})
			arr = this.props.data
		}
		else{
			arr = this.state.data;
		}
		
		axios.post("/api/sections/delete", {id: val})
			.then(res => {
				let data = arr.filter(dt => {
					return dt.id !== val*1
				});
				this.setState({
					data: data
				})
			})
			.catch(err => console.warn(err));
		
	}

	render() {
		let data;
		if(this.state.data.length > 0)
		{
			data = this.state.data;
		}
		else{
			data = this.props.data;
		}

		if(data.length === 0){
			return (<Spinner />);
		}
		
		let displayData = data.map( (section, i) => {
			let bg2 = ( (i%2 === 0) ? "#ececec" : '' )
			return (
				<tr className={`p-2 m-1`} key={i} style={{ borderBottom: "1px solid #000", background: bg2 }}>
					<td className="p-2">
						<i onClick={this.deleteData} value={section.id} className="delete-user text-danger fa fa-window-close mr-2"></i>	
						<Link to={`/admin/edit/section/${section.id}`} >
						
							{section.name.replace(/_/g,' ')}
						</Link>	
					</td>
					<td><Link to={section.url}>{section.url}</Link></td>
					<td>{section.page_type}</td>
					<td>{section.parent}</td>
					
				</tr>
			)
		})

		return (
			<div>
				
				<div className="row text-center">
					
					<div className="col">
						{displayData.length > 0 ? null : <div className="text-danger h4 text-center mt-4 p-3">No sections match your search</div> }
					</div>
					
				</div>
				<table className="w-100">
					
					<thead>
						<tr>
							<td className="h5 p-1"><u>Name</u></td>
							<td className="h5"><u>url</u></td>
							<td className="h5"><u>PageType</u></td>
							<td className="h5"><u>Parent</u></td>
						</tr>
					</thead>
					<tbody>
						{data.length > 0 ? displayData : null}
					</tbody>
				</table>	
			</div>
		);
		
	}
}

export default SectionsTable;