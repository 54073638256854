import React, { Component } from 'react'
import axios from "axios";

export default class LinuxMagazineNews extends Component {
	constructor(props){
		super(props);
		this.state = {
			content: []
		}
	}

	getData(){
		axios.get("/api/feeds/linux/tech")
			.then(res => {
				
				this.setState({content: res.data});
			})
	}

	componentDidMount(){
		this.getData();
	}

	render() {
		let displayData = this.state.content.map(item => {
			return(
				<div key={item.title} className="col-6 col-md-6 col-lg-6 col-xl-6 mb-2">
					<div className="news-card card h-100 pb-2">
						<a href={item.link} rel="noopener noreferrer" target="_blank">
							<div className="text-center ht-2 p-2 pb-0">
								<b>{item.title}</b>
							</div>
						</a>
					</div>
				</div>
			);
		});

		return (
			<div className="card p-2 mb-1 mt-0">
				<div className="h4 text-center mb-3 text-dark">
					<img alt="linux magazine logo" src="/images/icons/linux-mag-logo.png" style={{ height: "45px", width: "auto", position: "relative", top: "3px", marginRight: "10px" }}/>
					News
				</div>
				<div className="row">
					{displayData}
				</div>
			</div>
		)
	}
}
