import React, { Component } from "react"; 
import {Link} from "react-router-dom";
import axios from "axios";
import SectionsTable from "../Sections/SectionsTable";
import TextFieldGroup from "../../common/TextFieldGroup";
import ContentTable from "../Content/ContentTable";
import AssetsTable from "../Assets/AssetsTable";
class ManageData extends Component {
	constructor(props){
		super(props);
		this.state = {
			errors: {},
			limit: 12,
			page: 1,
			dataType: '',
			search: '',
			data: [],
			count: 0,
		}
		this.getData = this.getData.bind(this);
		this.onChange = this.onChange.bind(this);
		this.deleteData = this.deleteData.bind(this);
		this.changePage = this.changePage.bind(this);
		this.searchByString = this.searchByString.bind(this);
	}

	getData(page){
		let dataType = this.props.dataType;
		this.setState({dataType: dataType});
		let api = `/api/${dataType}`;
		axios.post(api, {limit: this.state.limit, page: page})
			.then(dt => {
				this.setState({data: dt.data})
			})
			.catch(err => console.warn(err) );
	}



	searchByString(str, page){
		
		axios.post(`/api/${this.state.dataType}/search`, {search: str, count: true})
			.then(count => {
				this.setState({count: count.data.count})
				axios.post(`/api/${this.state.dataType}/search`, {search: str, limit: this.state.limit, page: page})
					.then(res => {
						this.setState({data: res.data})
					})
					.catch(err => {
						console.warn(err);
						this.setState({errors: err})
					})
			});
		
	}

	onChange(e){
		if(e.target.name === "search")
		{
			this.setState({[e.target.name]: e.target.value});
			this.searchByString(e.target.value, 1);
		}
	}

	changePage(e){
		let direction = e.target.getAttribute('value');
		let page = this.state.page;
		if(direction === "up"){
			this.setState({page: page+1})
			if(this.state.search.length > 0){
				this.searchByString(this.state.search, page+1);
			}
			else{
				this.getData(page+1);
			}
		}
		else{
			this.setState({page: page-1})
			if(this.state.search.length > 0){
				this.searchByString(this.state.search, page-1);
			}
			else{
				this.getData(page-1);
			}
		}
	}
	
	deleteData(e){

	}

	componentDidUpdate(prevProps, prevState){
		if(this.props.dataType !== prevProps.dataType){
			return true;
		}
	}

	componentDidMount(){
		let dataType = this.props.dataType;
		let api = `/api/${dataType}`;
		
		axios.post(api, {count: true})
		.then(count => {
			this.setState({count: count.data.count})
		});

		this.getData(1);
	}
	
	render() {
		let {errors, data} = this.state;
		let displayData;
		switch(this.state.dataType)
		{
			case "sections":
				displayData=(
					<SectionsTable data={data} />
				)
				break;
			case "content":
				displayData=(
					<ContentTable data={data} />
				)
				break;
			case "assets":
				displayData=(
					<AssetsTable data={data} />
				)
				break;
			default:
				displayData=(
					<div>default</div>
				)
		}
		
		let updatedId = null;
		let updatedTitle =null;

		return (
		<div>
			
			<h3 className="text-center text-capitalize" >
				<div className='text-center'>
				<Link className="btn btn-success float-left text-white t mt-1 d-block d-lg-" to={`/admin/new/${this.state.dataType}/0`}>New {this.state.dataType}</Link>
					LukeDev {this.state.dataType}
				</div>
				
			</h3>
			<div className="user-search mt-2 col" style={{ minWidth: "250px", maxWidth: "600px", margin: "0 auto"  }}>
				<TextFieldGroup
					placeholder="Search"
					name="search"
					value={this.state.search}
					onChange={this.onChange}
					error={errors.search}
					style={{ width: "40px" }}
				/>
			</div>
			<div className="message text-info">{updatedId ? `The post ${updatedTitle} with id ${updatedId} has been updated` : null}</div>
			<div className="h4" >
				<span style={{ display: "inline-block" }}>
					Showing {(this.state.page-1)*this.state.limit +1} - 
					{
						(this.state.page)*this.state.limit > this.state.count ? this.state.count : (this.state.page)*this.state.limit
					} 
					&nbsp;of&nbsp;
					{ this.state.count}
				</span>
				<span className="float-right">
					{
						this.state.page > 1 ? 
						<button className="d-inline mr-2 btn btn-secondary" onClick={this.changePage} value="down">Prev Page</button> : 
						<button className="d-inline mr-2 btn btn-secondary" value="down" disabled>Prev Page</button> 
					}
					{ 
						this.state.page < (this.state.count/this.state.limit) ? 
						<button className="d-inline btn btn-info" onClick={this.changePage} value="up">Next Page</button> : 
						<button className="d-inline btn btn-info" value="up" disabled>Next Page</button> 
					}
				</span>
			</div>
			
			
			{displayData}

		</div>
		);
	}
}

export default ManageData;