import React, { Component } from "react"; 
import { Link } from "react-router-dom";

class ManageAssets extends Component {
	
	render() {
		return (
			<div className="manage-assets mt-1">
				<div className="ml-5 mr-5">
					<h3 className="text-center">Manage Assets</h3>
					<div className="row">
						<Link class="btn btn-success text-white" to="add-assets">Add Asset</Link>
					</div>
					<div className="row">
						hippies
					</div>
				</div>
			</div>
		);
	}
	
}

export default ManageAssets;