import React, { Component } from "react";

export default class ImageLoader extends Component {
	loadNoContent(e){
		e.target.src = "/images/icons/nocontent.jpg"
	}
	
	render() {
		let src;
		if(this.props.size)
		{
			if(this.props.size === "orig")
			{
				src = this.props.src;
			}
			else{
				src = this.props.src.split(".").slice(0, -1).join(".") +
				"_" +
				this.props.size +
				".jpg"
			}
		}
		else {
			src = this.props.src.split(".").slice(0, -1).join(".") + "_md.jpg"
		}

		let style ={};
		if(this.props.height){
				style.width = "auto";
				style.height = this.props.height;
		}

    return (
      <React.Fragment>
        <img
          src={src}
					alt={this.props.alt ? this.props.alt : ""}
					style={style}
					onError={this.loadNoContent} 
					className="expandable-image"
        />
      </React.Fragment>
    );
  }
}
