import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaFieldGroup from "../../common/TextAreaFieldGroup";
import {
  getCurrentProfile,
  deleteEducation,
  addEducation,
} from "../../../actions/profileActions";
import isEmpty from "../../../validation/is-empty";
import { Link, withRouter } from "react-router-dom";
import { formatDate } from "../../../utils/commonTools";

class EditEducation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      school: "",
      degree: "",
      fieldofstudy: "",
      from: "",
      to: "",
      current: false,
      description: "",
      errors: {},
      disabled: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    }

    if (
      (!isEmpty(this.props.profile) || this.props.profile !== null) &&
      this.props.profile.loading === false &&
      this.props.profile !== prevProps.profile
    ) {
      let education = this.props.profile.profile.education;
      education = education.filter(
        (edu) => edu._id === this.props.match.params.edu_id
      )[0];
      if (education === []) {
        return;
      }
      education.errors = {};

      education.from = formatDate(education.from);
      education.to = formatDate(education.to);
      education.current = Boolean(education.current);
      this.setState(education);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let data = this.state;
    this.props.addEducation(data, this.props.history, true);
  }

  onChange(e) {
    if (e.target.name === "current") {
      this.setState({
        disabled: !this.state.disabled,
        current: !this.state.current,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  //select options for status
  statusOptions = [
    { label: "* Select Professional Status", value: 0 },
    { label: "Developer", value: "Developer" },
    { label: "Junior Developer", value: "Junior Developer" },
    { label: "Senior Developer", value: "Senior Developer" },
    { label: "Manager", value: "Manager" },
    { label: "Student or Learning", value: "Student or Learning" },
    { label: "Instructor or Teacher", value: "Instructor or Teacher" },
    { label: "Intern", value: "Intern" },
    { label: "Other", value: "Other" },
  ];

  render() {
    const { errors } = this.state;
    return (
      <div className="add-education">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <Link to="/dashboard" className="btn btn-light">
                Go Back
              </Link>
              <h1 className="display-4 text-center">Add Education</h1>
              <p className="lead text-center">
                Add any school and associated information about your degree
              </p>
              <small className="d-block pb3">* = required fields</small>

              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="* School"
                  name="school"
                  value={this.state.school}
                  onChange={this.onChange}
                  error={errors.school}
                />
                <TextFieldGroup
                  placeholder="* Degree"
                  name="degree"
                  value={this.state.degree}
                  onChange={this.onChange}
                  error={errors.degree}
                />
                <TextFieldGroup
                  placeholder="* Field Of Study"
                  name="fieldofstudy"
                  value={this.state.fieldofstudy}
                  onChange={this.onChange}
                  error={errors.fieldofstudy}
                />
                <h6>From Date</h6>
                <TextFieldGroup
                  name="from"
                  type="date"
                  value={this.state.from}
                  onChange={this.onChange}
                  error={errors.from}
                />
                <h6>From Date</h6>
                <TextFieldGroup
                  name="to"
                  type="date"
                  value={this.state.to}
                  onChange={this.onChange}
                  error={errors.to}
                  disabled={this.state.disabled ? "disabled" : ""}
                />
                <div className="form-check mb-4">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="current"
                    value={this.state.current}
                    onChange={this.onChange}
                    checked={Boolean(this.state.current)}
                    id="current"
                  />
                  <label htmlFor="current" className="form-check-label">
                    Current Position
                  </label>
                </div>
                <TextAreaFieldGroup
                  placeholder="Degree Description"
                  name="description"
                  value={this.state.description}
                  onChange={this.onChange}
                  error={errors.description}
                />
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-info btn-block mt4"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditEducation.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  addEducation: PropTypes.func.isRequired,
  deleteEducation: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  deleteEducation,
  addEducation,
  getCurrentProfile,
})(withRouter(EditEducation));
