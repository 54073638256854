import React, { Component } from "react";
import HalfWidthCard from "../common/cards/HalfWidthCard";
import FullWidthCard from "../common/cards/FullWidthCard";

class CardsSection extends Component {
  render() {
    let cards = this.props.cards;
    let cardsOutput;
    let category = this.props.category;
    if (cards && cards.length > 0) {
      cardsOutput = cards.map((card) => {
        if (
          card.Content &&
          card.Content.title !== "home" &&
          card.Content.title !== category
        ) {
          if (this.props.sizing === "full") {
            return (
              <FullWidthCard
                key={card.id}
                card={card}
                nodesc={this.props.nodesc}
              />
            );
          }
          return <HalfWidthCard key={card.id} card={card} />;
        }
        return null;
      });
    }
    return <div className="row pl-1 pr-1 mt- text-dark">{cardsOutput}</div>;
  }
}

export default CardsSection;
