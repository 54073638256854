import React, { Component } from "react"; 
import axios from "axios";
import TextFieldGroup from "../../common/TextFieldGroup";
class NewSection extends Component {
	constructor(props){
		super(props);
		this.state = {
			errors: {},
			data: {},
			name: '',
			site_name: '',
			url: '',
			page_type: '',
			parent: '',
			content_id: '',
			saved: false,
			dataType: '', 
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onClick(e){
		if(e.target.value === "cancel")
		{
			document.location.href = `/admin/manage-data/${this.props.dataType}`;
		}
		if(e.target.value === "goBack")
		{
			document.location.href = `/admin/manage-data/${this.props.dataType}`;
		}
	}

	onChange(e){
		this.setState({
				[e.target.name]: e.target.value,
				saved: false,
				dataType: this.props.dataType
		});
	}

	onSubmit(e){
		e.preventDefault();
		let errors={};
		if(this.state.name.length <= 3 )
		{
			errors.name = "The title must be over 5 characters long"
		}
		if(this.state.site_name.length < 5 )
		{
			errors.site_name = "The site name must be over 5 characters long"
		}
		if(this.state.url.length < 5 )
		{
			errors.url = "The url must be over 5 characters long"
		}
		if(this.state.page_type.length < 5 )
		{
			errors.page_type = "The page type must be over 5 characters long"
		}
		if(this.state.parent.length < 3 )
		{
			errors.parent = "The parent field must be over 3 characters long"
		}
		if(this.state.content_id.length === 0 )
		{
			errors.content_id = "The content ID must not be blank"
		}
		
		if(errors.length >0)
		{
			console.warn(errors);
			this.setState({errors: errors});	
			return;
		}
		
		let newSection = {
			name: this.state.name.trim(),
			site_name: this.state.site_name.trim(),
			url: this.state.url,
			page_type: this.state.page_type,
			parent: this.state.parent,
			content_id: this.state.content_id,
		}

		axios.post("/api/sections/insert", newSection)
			.then(res => {
				this.setState({saved: true});
				document.location.href = "/admin/manage-data/sections"
			})
			.catch(err => {
				this.setState({errors: err.response.data})
			})
		
	}
	
	render() {
		let {errors} = this.state;
		let data= this.state;
		let fields = [
			"name", "site_name", "url", "page_type", "parent", "content_id"
		];
		let fieldInputs = fields.map((field, i) => {
			return (
				<div key={i}>
					<TextFieldGroup
						name={field}
						type={field}
						value={data && data[field] ? data[field].toString() : ''}
						onChange={this.onChange}
						error={errors[field]}
					/>
				</div>
			)
		});

		return (
			<div>
				<h3 className="text-center text-capitalize">New Section: {data && data.name}</h3>

				<div className="row">
					<div className="container">
						<form onSubmit={this.onSubmit}>
						{fieldInputs}
						<div className="text-center mt-3">
							<input type="submit" className="btn btn-success pl-3 pr-3" value="Save"></input>
							<button className="btn btn-danger ml-2" onClick={this.onClick} value="cancel">Cancel</button>
						</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

export default NewSection;