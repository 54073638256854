import React, { Component } from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {editGame, getGame, saveGame} from "../../../../../actions/farkleActions";
import {getUsers} from "../../../../../actions/authActions";

class ViewGame extends Component {
	constructor(props){
		super(props);
		this.state = {
			game: {}
		}
	}
	
	componentDidMount(){
		this.props.getGame(this.props.match.params.id);
		this.props.getUsers();
	}
	
	render() {
		if(typeof this.props.games === "undefined" && this.props.games !== {})
		{
			return( <div className="h3">Loading</div>)
		}
		else{
			let game = this.props.games.game;
			let user = this.props.auth.user;
			// let players = this.props.auth.players;
			let availablePlayers;

			// if(players && players.length > 0){
			// 	availablePlayers = players.map(player =>{
			// 		return null;
			// 	});
			// }

			if(game.user !== user.id)
			{
				return ( <div className="h3">You are not authorized to edit this game</div>)
			}
			else{
				return (
					<div className="container">
						<div className="h2">View {game.name}</div>
						<div>
							<div className="h4">Current Players:</div>
							<ul>
								<li>{user.name}</li>
								
							</ul>
							<div className="h4">Available Players:</div>
							<ul>
								<li>{availablePlayers}</li>	
							</ul>
						</div>
						<div className="btn btn-success">Add Players</div>
					</div>
				)	
			}
				
		}
		
	}
}

ViewGame.propTypes = {
	game: PropTypes.object,
	auth: PropTypes.object.isRequired,
	editGame: PropTypes.func.isRequired,
	getUsers: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	games: state.games,
	auth: state.auth
})

export default connect(mapStateToProps, { editGame, getGame, saveGame, getUsers } )(ViewGame);