import { SET_CURRENT_USER, GET_USERS, CHANGE_AVATAR, UPDATE_USER_NAME } from '../actions/types';
import isEmpty from '../validation/is-empty';

const initialState = {
	isAuthenticated: false,
	user: {},
	users: [],
}

export default function(state = initialState, action) {
	switch(action.type) {
		case SET_CURRENT_USER:
			return {
				...state,
				isAuthenticated: !isEmpty(action.payload),
				user: action.payload
			}		
		case CHANGE_AVATAR:
			return {
				...state,
					user: {
						...state.user,
						avatar: action.payload
					}
				}
		case UPDATE_USER_NAME:
				console.log("here");
					return{
						...state,
						user: {
							...state.user,
							name: action.payload,
						}
					}	
		case GET_USERS:
			return{
				...state,
				users: action.payload,
			}
				
		default: 
			return state;
	}
}