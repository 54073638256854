import * as postTypes from "../actions/farkleTypes";

const initialState = {
  farkle: [],
  game: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case postTypes.GET_ALL_GAMES:
      return {
        ...state,
        farkle: action.payload,
        loading: false,
      };
    case postTypes.GET_GAME:
      return {
        ...state,
        game: action.payload,
      };

    default:
      return state;
  }
}
