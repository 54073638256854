import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import axios from "axios";
import { Link } from "react-router-dom";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      sent: false,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.sendCode = this.sendCode.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth !== prevProps.auth) {
      if (this.props.auth.isAuthenticated) {
        this.props.history.push("./dashboard");
      }
    }

    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  sendCode(e) {
    axios
      .post("/api/users/sendConfirmCode", { email: this.state.email })
      .then((res) => {
        this.setState({ sent: true });
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const user = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.loginUser(user);
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">Log In</h1>
              <p className="lead text-center">
                Sign in to your DevConnector account
              </p>
              <form onSubmit={this.onSubmit}>
                {this.props.location.search === "?passwordReset=true" ? (
                  <div className="text-success">
                    Your password has been updated...
                  </div>
                ) : null}
                {errors.apifailure && (
                  <div className="text-danger">
                    Connection to Login API failed Status: {errors.text}
                  </div>
                )}
                {errors.approved && (
                  <div className="text-danger text-center">
                    The admins have not approved your account yet. If it has
                    been more than 24 hours, please email a reminder to <br />
                    <i className="fa fa-envelope-open text-info mr-1"></i>
                    <a href="mailto:luke@lukedev.net">LukeDev Admins</a>
                  </div>
                )}
                {errors.confirmed && (
                  <div className="text-center">
                    <div className="text-danger">{errors.confirmed}</div>
                    <div className="h4">
                      If you have not received your code, please click{" "}
                      <div className="btn btn-info" onClick={this.sendCode}>
                        Here
                      </div>
                    </div>
                  </div>
                )}
                {this.state.sent && (
                  <div className="text-center h5 text-info">
                    Email has been sent
                  </div>
                )}
                <TextFieldGroup
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                />
                <TextFieldGroup
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                />
                <div className="row">
                  <div className="col col-6">
                    <input
                      type="submit"
                      className="btn btn-info mt-4 pr-1 w-100"
                    />
                  </div>
                  <div className="col col-6">
                    <Link
                      to="/forgot-password"
                      className="btn btn-secondary text-light mt-4 pl-1 w-100"
                    >
                      Forgot Password
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
