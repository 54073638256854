import React, { Component } from 'react'
import axios from "axios";

export default class WiredNews extends Component {
	constructor(props){
		super(props);
		this.state = {
			content: []
		}
	}

	getData(){
		axios.get("/api/feeds/wired/tech")
			.then(res => {
				this.setState({content: res.data});
			})
	}

	componentDidMount(){
		this.getData();
	}

	render() {
		let displayData = this.state.content.map(item => {
			return(
				<div key={item.title} className="col-6 col-md-6 col-lg-6 col-xl-3 mb-2">
					<div className="news-card card h-100 pb-2" style={{ background: "none" }}>
						<a href={item.link} rel="noopener noreferrer" target="_blank">
							<div style={{  height: "250px" }}>
									<img alt={item.title} src={item.thumbnail}></img>
							</div>
							<div className="align-bottom align-text-bottom ht-2 p-2 pb-0 mt-2" style={{ position: "absolute",
								bottom: "10px" }} >
								<b>{item.title}</b>
							</div>
						</a>
					</div>
				</div>
			);
		});

		return (
			<div className="card p-2 mb-1 mt-2">
				<div className="h4 text-center mb-3 text-dark">
					<img alt="wired logo" src="/images/contentImages/wired-logo.jpg" style={{ height: "25px", width: "auto", position: "relative", top: "-3px", marginRight: "10px" }}/>
					News
				</div>
				<div className="row">
					{displayData}
				</div>
			</div>
		)
	}
}
