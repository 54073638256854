import React, { Component } from "react"; 
import {Link} from "react-router-dom";

class AdminNav extends Component {
	render() {
		return (
			<div id="admin-navbar" style={{  minHeight: "81vh" }}>
				<ul id="admin-links text-center" className="" style={{ paddingLeft: "0px" }}>
					<Link to="/admin/">
						<li className="nav-link pl-4">
							Admin Home
						</li>
					</Link>	
					<Link to="/admin/users">
						<li className="nav-link pl-4">
							Manage Users
						</li>
					</Link>
					<a href="/admin/manage-data/sections">
						<li className="nav-link pl-4">
							Manage Sections
						</li>
					</a>
					<a href="/admin/manage-data/content">
						<li className="nav-link pl-4">
							Manage Content
						</li>
					</a>
					<a href="/admin/manage-data/assets">
						<li className="nav-link pl-4">
							Manage Assets
						</li>
					</a>
				</ul>
			</div>
		);
	}
}

export default AdminNav;