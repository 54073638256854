import React, { Component } from "react";
import axios from "axios";
import TextFieldGroup from "../../common/TextFieldGroup";
import { Link } from "react-router-dom";

class EditAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      data: {},
      id: "",
      name: "",
      file_name: "",
      file_path: "",
      alt: "",
      rotation: "",
      saved: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.checkValidJSON = this.checkValidJSON.bind(this);
  }

  getData() {
    let sid = this.props.sid;
    axios.get(`/api/assets/asset/${sid}`).then((res) => {
      this.setState({
        id: res.data.id,
        name: res.data.name,
        file_name: res.data.file_name,
        file_path: res.data.file_path,
        alt: res.data.alt,
        rotation: res.data.rotation,
      });
    });
  }

  componentDidMount() {
    this.getData();
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      saved: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();
		let errors = {};
		
    if (this.state.file_path.length <= 10) {
      errors.file_path = "The file path must be over 10 characters long";
    }
    if (this.state.name.length < 3) {
      errors.name = "The name must be over 3 characters long";
		}
		if (this.state.name.alt < 5) {
      errors.name = "The alt text must be over 5 characters long";
		}
		
		if ( !['0', '90', -'90', '180', 'fh', 'fv'].includes(this.state.rotation) )
		{
			errors.rotation = "Rotation must be one of the following: 0, 90, -90, 180, fh, or fv"
		}
    if (Object.keys(errors).length) {
      this.setState({ errors: errors });
      return;
    }

    let updatedAsset = {
      id: this.props.sid,
      name: this.state.name.trim(),
      file_name: this.state.file_name.trim(),
      file_path: this.state.file_path.trim(),
      alt: this.state.alt,
      rotation: this.state.rotation,
		};
		
    axios
      .post("/api/assets/update/", updatedAsset)
      .then((res) => {
        this.setState({ saved: true });
      })
      .catch((err) => {
        this.setState({ errors: err.response.data });
      });
  }

  checkValidJSON(str) {
    if (
      /^[\],:{}\s]*$/.test(
        str
          .replace(/\\["\\/bfnrtu]/g, "@")
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g,
            "]"
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    let { errors } = this.state;
    return (
      <div id="edit-content-form">
        <h3 className="text-center text-capitalize mt-2 ">
          Edit Content: {this.state.title}
        </h3>
        {this.state.saved ? (
          <h4 className="text-success text-center mt-1">New Content Saved</h4>
        ) : this.state.saved === null ? (
          <h4 className="text-center text-success mt-1">Content Unchanged</h4>
        ) : (
          <h4 className="text-warning text-center mt-1">Content Not Saved</h4>
        )}
        <div className="row">
          <div className="container">
            <form onSubmit={this.onSubmit}>
              <div className="row">
                <div className="col col-12 col-xl-8 pt-2 text-center">
                  <div>
                    <img
                      alt={this.state.alt}
                      src={this.state.file_path}
                      
                    ></img>
                  </div>
                  <div>
                    <label className="mr-2">File Path: </label>
                    <input
                      disabled
                      name="file_path"
                      className=" mt-2 w-75 pl-2 pr-2"
                      value={this.state.file_path}
                    ></input>
                  </div>
                  <button className="btn btn-warning">Move Asset</button>
                </div>
                <div className="col col-12 col-xl-4">
                  <TextFieldGroup
                    name="name"
                    value={this.state.name ? this.state.name : ""}
                    onChange={this.onChange}
                    error={errors.name}
                  />
                  <TextFieldGroup
                    name="alt"
                    value={this.state.alt ? this.state.alt : ""}
                    onChange={this.onChange}
                    error={errors.alt}
                  />
                  <TextFieldGroup
                    name="rotation"
                    value={this.state.rotation ? this.state.rotation : ""}
                    onChange={this.onChange}
                    error={errors.rotation}
                  />
                  <div className="text-center mt-3">
                    <input type="submit" className="btn btn-info"></input>
                    <Link
                      to="/admin/manage-data/assets"
                      className="btn btn-danger text-white ml-2"
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default EditAsset;