import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { getSectionData } from "../../actions/sectionActions";
import RedditModule from "../common/modules/Reddit";
import LeftColContent from "./LeftColContent";
import CategoryLandingHeader from "./CategoryLandingHeader";
import TitleBar from "../common/TitleBar";
import CardsSection from "../category-page/CardsSection";
import WiredNews from "../common/modules/WiredNews";
import LinuxMagazineNews from "../common/modules/LinuxMagazineNews";
import DockerNews from "../common/modules/DockerNews";

class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: {},
      currentInfo: "",
    };
  }

  getContent() {
    axios
      .get("/api/sections/children/" + this.props.match.params.category)
      .then((res) => {
        if (res) {
          this.setState({ cards: res.data });
        } else {
          this.setState({ cards: {} });
        }
      })
      .catch((err) => {
        console.warn({ err: err });
      });

    axios
      .get("/api/sections/category/" + this.props.match.params.category)
      .then((res) => {
        this.setState({ currentInfo: res.data[0].Content });
      })
      .catch((err) => {
        console.warn({ err: err });
      });
  }

  componentDidMount() {
    this.getContent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.getContent();
    }
  }

  render() {
    // Set info variable for current category
    let currentInfo;
    if (typeof this.state.currentInfo.id !== "undefined") {
      currentInfo = this.state.currentInfo;
    }

    let redditStreams = "";
    let mycategory = this.props.match.params.category;
    switch (this.props.match.params.category) {
      case "projects":
        redditStreams = (
          <React.Fragment>
            <RedditModule key="reactjs" stream="reactjs" />
            <RedditModule key="linux" stream="linux" />
            <RedditModule key="docker" stream="docker" />
            <RedditModule key="networking" stream="networking" />
          </React.Fragment>
        );
        break;
      case "tools":
        redditStreams = (
          <React.Fragment>
            <RedditModule key="linux" stream="linux"></RedditModule>
            <RedditModule key="ssh" stream="ssh"></RedditModule>
            <RedditModule key="imaging" stream="imaging"></RedditModule>
          </React.Fragment>
        );
				break;
				
      default:
        redditStreams = (
          <React.Fragment>
            <RedditModule
              key={mycategory}
              stream={mycategory}
              limit="10"
            ></RedditModule>
          </React.Fragment>
        );
        break;
		}
		let rrNews;
		switch (this.props.match.params.category) {
      case "docker":
				rrNews = (<DockerNews />)
			break;

			default:
				break;
		}
    // FINAL RENDER
    return (
      <div className="landing category-landing">
        <div className="dark-overlay text-light pb-2 mt-2">
          {/*--------------Title Bar ----------------*/}
          <TitleBar
            currentInfo={currentInfo}
            history={this.props.history}
          ></TitleBar>

          {/*----------------Header --------------------*/}
          <CategoryLandingHeader currentInfo={currentInfo} />
          {/*--------------END HEADER-------------------*/}

          <div className="category-landing container" >
            <div className="row">
              <div className="col col-12 col-lg-8 bg-light text-dark mt-3 pt-3">
                <LeftColContent
                  category={mycategory}
                  currentInfo={currentInfo}
                />
                <CardsSection
                  cards={this.state.cards}
                  category={this.props.match.params.category}
								/>
								<WiredNews />
								<LinuxMagazineNews />
              </div>
							<div className="col col-12 col-lg-4 mt-3 bg-light text-dark pt-4">
								<iframe
                  scrolling="no"
                  title="news-widget"
                  id="widget"
                  className="card mb-2"
                  src="/public/widget.html?gfd=76"
                  style={{ width: "100%", scroll: "none" }}
                />
                <h3 className="text-center">Related Reddit Streams</h3>
								{redditStreams}
								{rrNews}
              </div>
						</div>
						
          </div>
          {/*----------- Wired News --------------------  */}
					<div className="container">
						
					</div>
					
          {/*-------------END CARDS -------------- */}
        </div>
      </div>
    );
  }
}

CategoryPage.propTypes = {
  auth: PropTypes.object.isRequired,
  getSectionData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getSectionData })(CategoryPage);
