import React from "react";
import { Route } from "react-router-dom";
import UserRoutes from "./userRoutes";
import PetalRoutes from "./petalRoutes";
import ProjectsRoutes from "./projectsRoutes";
import UploaderRoutes from "./uploaderRoutes";

import { Component } from "react";
import Landing from "../components/layout/Landing";
import ContentRoutes from "./contentRouters";
import SocialRoutes from "./socialRoutes";
import AdminRoutes from "./adminRouters";

class AllRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/" component={Landing} />
        <UserRoutes />
        <PetalRoutes />
        <ProjectsRoutes />
        <ContentRoutes />
        <UploaderRoutes />
        <SocialRoutes />
        <AdminRoutes />
      </React.Fragment>
    );
  }
}

export default AllRoutes;
