import React, { Component } from "react";
import axios from "axios";

class RedditModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reddit_posts: [],
    };
  }

  componentDidMount() {
    let stream = this.props.stream;
    let limit = 5;

    if (this.props.limit) {
      limit = this.props.limit;
    }
    axios
      .get(`/api/feeds/reddit/${stream}?limit=${limit}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let ps = res.data;
        this.setState({ reddit_posts: ps });
      });
  }

  render() {
    return (
      <div className="card col-12 mb-2">
        <h4 className="text-dark text-center">
          <u>Reddit -> /r/{this.props.stream}</u>
        </h4>
        <ul
          className="text-dark"
          style={{ listStyleType: "none", padding: "0px" }}
        >
          {this.state.reddit_posts.map((post, index) => {
            return (
							<a key={"reddit"+index} className="reddit-item" target="_blank" rel="noopener noreferrer" href={post.url}>
								<li
									key={post.id}
									className="reddit-item p-1"
									style={{ borderBottom: "1px solid #ccc" }}
								>
                  {post.title}
								</li>
							</a>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default RedditModule;
