import React from "react";
import { Link } from "react-router-dom";

const ProfileActions = () => {
  return (
    <div className="profile-buttons btn-group mb-4 mt-2" role="group">
      <div className="row text-center">
          <div className="col col-6 col-sm-6 col-md-12 mb-3">
            <Link
              to="/change-profile-picture"
              className="btn btn-info text-white"
            >
              <i className="fa fa-user-circle  mr-1"></i>
              Profile Picture
            </Link>
          </div>
          <div className="col col-6 col-sm-6 col-md-12 mb-3">
            <Link to="/change-password" className="btn btn-info text-white">
              <i className="fa fa-cog  mr-1"></i>
              Change Password
            </Link>
          </div>
          <div className="col col-6 col-sm-6 col-md-12 mb-3">
            <Link to="/edit-profile" className="btn btn-info text-white">
              <i className="fa fa-user-circle  mr-1"></i>
              Edit Profile
            </Link>
          </div>
          <div className="col col-6 col-sm-6 col-md-12 mb-3">
            <Link to="/add-experience" className="btn btn-info text-white">
              <i className="fa fa-black-tie  mr-1 mr-1"></i>
              Add Experience
            </Link>
          </div>
          <div className="col col-6 col-sm-6 col-md-12 mb-3 ">
            <Link to="/add-education" className="btn btn-info text-white">
              <i className="fa fa-graduation-cap  mr-1 mr-1"></i>
              Add Education
            </Link>
          </div>
      </div>
    </div>
  );
};

export default ProfileActions;
