import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import "./Navbar.css";
import { Fragment } from "react";
import ImageLoader from "../common/ImageLoader";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.toggleRef = React.createRef();
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {}

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props !== prevProps) {
      return true;
    }
  }

  render() {
		const { isAuthenticated, user } = this.props.auth;
		// if(user.avatar.indexOf("gravatar") <0 )
		// {
		// 	user.avatar = 
		// }
    const authLinks = (
      <ul className="navbar-nav ml-auto">
        {this.props.auth.user.role === "admin" ? (
          <li className="nav-item">
            <Link
              className="admin-nav-link nav-link pl-3 pr-3"
              to="/admin"
              style={{
                marginTop: "-3px",
                height: "62px",
                borderRight: "1px solid #ccc",
                borderLeft: "1px solid #ccc",
              }}
            >
              <div className="admin-text" style={{ marginTop: "13px" }}>
                Admin
              </div>
            </Link>
          </li>
        ) : null}
        <li className="nav-item pr-1 pl-1 nav-with-dropdown text-bold text-light">
          <div id="toggle-account" className="dropdown-toggle nav-link" >
            <ImageLoader
              className=""
              src={
                user.avatar
                  ? user.avatar
                  : "//www.gravatar.com/avatar/57efbee2d5b4f2bdc6726c5a59f433b4?s=200&amp;r=pg&amp;d=mm"
              }
							alt={user.name}
							height="43px"
              
              title="You must have a Gravatar connected to your email to display an image"
            />
            <span style={{ position: "relative", top: "2px", marginLeft: "7px" }}>
              {user.name}
            </span>
          </div>
          <ul
            id="account-dropdown"
            className="dropdown-menu  ml-2"
            ref={this.toggleRef}
          >
            <li>
              <Link
                className="dropdown-item nav-link pl-2 pr-2"
                to="/projects"
                style={{ marginTop: "0px" }}
              >
                My Projects
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item nav-link pl-2 pr-2"
                to="/dashboard"
                style={{ marginTop: "0px" }}
              >
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/"
                onClick={this.onLogoutClick.bind(this)}
                className="dropdown-item nav-link pl-2 pr-2"
                style={{ marginTop: "3px" }}
              >
                <span style={{ marginTop: "2px" }}>Logout</span>
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    );

    const guestLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link
            className="nav-link"
            to="/register"
            style={{ marginTop: "0px" }}
          >
            Sign Up
          </Link>
        </li>

        <li className="nav-item">
          <Link className="nav-link" to="/login" style={{ marginTop: "0px" }}>
            Login
          </Link>
        </li>
      </ul>
    );

    return (
      <Fragment>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark mb-4" style={{  borderBottom: "1px solid #666" }}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img
                src="/img/lukedev_logo_250x63.png"
                style={{ width: "80%" }}
                alt="LukeDev Logo"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#mobile-nav"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="mobile-nav">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item pr-1 pl-1 nav-with-dropdown">
                  <div
                    id="toggle-projects"
                    className="nav-link nav-top dropdown-toggle"
                    style={{ marginTop: "0px" }}
                    onClick={this.toggleDropdown1}
                  >
                    Projects
                  </div>
                  <ul
                    id="projects-dropdown"
                    className="dropdown-menu  ml-2"
                    ref={this.toggleRef}
                  >
                    <li>
                      <Link
                        className="dropdown-item nav-link pl-2 pr-2"
                        to="/category/projects"
                      >
                        Overview
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item nav-link pl-2 pr-2"
                        to="/PETAL"
                      >
                        PETAL
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item nav-link pl-2 pr-2"
                        to="/category/landing/networks"
                      >
                        Networks
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item nav-link pl-2 pr-2"
                        to="/category/landing/docker"
                      >
                        Docker
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item nav-link pl-2 pr-2"
                        to="/category/landing/linux"
                      >
                        Linux
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item nav-link pl-2 pr-2"
                        to="/category/landing/tools"
                      >
                        Tools
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item pr-1 pl-1 nav-with-dropdown">
                  <div
                    to="/social/profiles"
                    id="toggle-social"
                    className="nav-link dropdown-toggle nav-top"
                    style={{ marginTop: "0px" }}
                    onClick={this.toggleDropdown1}
                  >
                    Social
                  </div>
                  <ul
                    id="social-dropdown"
                    className="dropdown-menu  ml-2"
                    ref={this.toggleRef}
                  >
                    <li>
                      <Link
                        className="dropdown-item nav-link pl-2 pr-2"
                        to="/dashboard"
                      >
                        My Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item nav-link pl-2 pr-2"
                        to="/social/profiles"
                      >
                        User Profiles
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item nav-link pl-2 pr-2"
                        to="/social/friends"
                      >
                        Friends
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item nav-link pl-2 pr-2"
                        to="/social/posts"
                        style={{ marginTop: "0px" }}
                      >
                        Post Feed
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item pr-1 pl-1">
                  <a
                    className="nav-link nav-top"
                    rel="noopener noreferrer"
                    href="https://photos.lukedev.net"
                    style={{ marginTop: "0px" }}
                    target="_blank"
                  >
                    Photography
                  </a>
                </li>
              </ul>

              {isAuthenticated ? authLinks : guestLinks}
            </div>
          </div>
        </nav>
        <div style={{ height: "85px", display: "block" }}></div>
      </Fragment>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Navbar);
