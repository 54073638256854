import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { getSectionData } from "../../actions/sectionActions";
import { Link } from "react-router-dom";

class ProjectsModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: {},
      currentInfo: "",
    };
  }

  getContent() {
    axios
      .get("/api/sections/children/projects")
      .then((res) => {
        if (res) {
          this.setState({ cards: res.data });
        } else {
          this.setState({ cards: {} });
        }
      })
      .catch((err) => {
        console.warn({ err: err });
      });
  }

  componentDidMount() {
    this.getContent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.getContent();
    }
  }

  render() {
    let projectsContent;
    if (this.state.cards.length > 0) {
      projectsContent = this.state.cards.map((card, i) => {
        return (
          <div key={i} className="project-item pl-2 pr-2">
            <Link
              to={card.url}
              className="underline h4 text-info text-center text-capitalize"
            >
              {card.Content.title.replace(/_/g, " ")}
            </Link>
            <p>{card.Content.slug}</p>
          </div>
        );
      });
    }
    return (
      <div className="card mb-2">
        <h4 className="text-center">
          <u>Project Links</u>
        </h4>
        {projectsContent}
      </div>
    );
  }
}

ProjectsModule.propTypes = {
  auth: PropTypes.object.isRequired,
  getSectionData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getSectionData })(ProjectsModule);
