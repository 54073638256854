import React, { Component, Fragment } from "react";
import isEmpty from "../../../validation/is-empty";
import Spinner from "../../common/Spinner";
import Moment from "react-moment";

class ProfileCreds extends Component {
  render() {
    const { profile } = this.props;
    if (profile === null || profile.loading) {
      return <Spinner></Spinner>;
    }
    let desc;
    const experiences = profile.experience.map((exp, index) => {
      if (exp.current === true) {
        exp.to = "Current";
      } else {
        exp.to = <Moment format="MM/YYYY">{exp.to}</Moment>;
      }
      desc = exp.description.replace(/•/g, "").split(/(?:\r\n|\r|\n)/g);
      desc = desc.map((line, i) => (
        <li key={i} className="ml-1">
          {line}
        </li>
      ));
      return (
        <div key={index} className="col col-12 col-lg-12">
          <div className="card p-3 h-100 ">
            <h4>{exp.company}</h4>
            <p>
              <Moment format="MM/YYYY">{exp.from}</Moment> - {exp.to}
            </p>
            <div className="mb-2">
              <div className="h5">Position:</div>
              <div className="ml-2">{exp.title}</div>
            </div>
            <div>
              <div className="h5 mb-3">Description:</div>
              <ul className="pl-4">
                {!isEmpty(exp.description) ? desc : null}
              </ul>
            </div>
          </div>
        </div>
      );
    });
    let education;
    if (!isEmpty(profile.education)) {
      education = profile.education.map((edu, index) => {
        if (edu.current === true) {
          edu.to = "Current";
        } else {
          edu.to = <Moment format="MM/YYYY">{edu.to}</Moment>;
        }

        return (
          <div key={index} className="col col-12">
            <div className="card p-3 ">
              <h4>{edu.school}</h4>
              <p>
                <Moment format="MM/YYYY">{edu.from}</Moment> - {edu.to}
              </p>
              <p>
                <strong>Degree:</strong> {edu.degree}
              </p>
              <p>
                <strong>Description:</strong>{" "}
                {!isEmpty(edu.description) ? edu.description : null}
              </p>
            </div>
          </div>
        );
      });
    }

    return (
      <Fragment>
        <div className="row">
          <div
            className={
              profile.experience.length > 0 ? "col-md-12" : "col-md-6 d-none"
            }
          >
            <h3 className="text-center text-info mb-3">Experience</h3>
            <div className="row">{experiences}</div>
          </div>
        </div>
        <div className="row">
          <div
            className={
              profile.education.length > 0 ? "col-md-12" : "col-md-12 d-none"
            }
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <h3 className="text-center text-info pb-1 pt-2">Education</h3>

            {education}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ProfileCreds;
