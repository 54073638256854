import React, { Component } from "react"; 
import axios from "axios";
import {Link} from "react-router-dom";
import TextFieldGroup from "../../common/TextFieldGroup";

class ManageSections extends Component {
	constructor(props){
		super(props);
		this.state = {
			errors: {},
			search: '',
			sections: [],
		}
		this.onChange = this.onChange.bind(this);
	}
	
	getSections(){
		axios.get('/api/sections')
			.then(res => {
				this.setState({sections: res.data})
			})
	}

	componentDidMount(){
		this.getSections();
	}

	findSectionsByString(str){
		
		axios.post('/api/sections/search', {search: str})
			.then(res => {
				this.setState({sections: res.data})
			})
			.catch(err => {
				this.setState({errors: err})
			})
	}

	onChange(e){
		if(e.target.name === "search")
		{
			this.setState({[e.target.name]: e.target.value});
			this.findSectionsByString(e.target.value);
			
		}
		else{
			
		}
		
	}

	render() {
		let {errors} = this.state;
		let sectionData;
		sectionData = this.state.sections.map( (section, i) => {
			let bg2 = ( (i%2 === 0) ? "#ececec" : '' )
			return (
				<tr className={`p-2 m-1`} key={i} style={{ borderBottom: "1px solid #000", background: bg2 }}>
					<td className="p-2">
						<Link to={`/admin/edit-section/${section.id}`} >
							{section.name}
						</Link>	
					</td>
					<td><Link to={section.url}>{section.url}</Link></td>
					<td>{section.page_type}</td>
					<td>{section.parent}</td>
					
				</tr>
			)
		})

		return (
			<div>
				<h3 className="text-center">LukeDev sections</h3>
				<div className="user-search mt-2 col" style={{ minWidth: "400px", maxWidth: "600px", margin: "0 auto"  }}>
						<TextFieldGroup
						placeholder="Search"
						name="search"
						value={this.state.search}
						onChange={this.onChange}
						error={errors.search}
						style={{ width: "40px" }}
						/>
					</div>
				<div className="row text-center">
					
					<div className="col">
						{sectionData.length > 0 ? null : <div className="text-danger h4 text-center mt-4 p-3">No sections match your search</div> }
					</div>
					
				</div>
				<table className="w-100">
					
					<thead>
						<tr>
							<td className="h5 p-1"><u>Name</u></td>
							<td className="h5"><u>url</u></td>
							<td className="h5"><u>PageType</u></td>
							<td className="h5"><u>Parent</u></td>
						</tr>
					</thead>
					<tbody>
						{sectionData.length > 0 ? sectionData : null}
					</tbody>
				</table>	
			</div>
		);
	}
}

export default ManageSections;