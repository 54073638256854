import React, { Component } from "react";
import { Link } from "react-router-dom"; 

class AdminWelcome extends Component {
	render() {
		return (
			<div>
				<h3 className="text-center mt-2">Admin Homepage</h3>
				<div className="row mb-3">
					<div className="col-12">
						<h4 className="text-center text-info">Welcome, your majesty!!!  It is good to see you again.  I trust that you have had safe travels?</h4>
						<h4 className="text-center">What do we need to work on today?</h4>
					</div>
				</div>
				<div className="row">
					<div className="col-12 col-md-6 col-lg-6 col-xl-6 p-1">
						<div className="card p-2 h-100">
							<Link className="h4" to="/admin/users">Manage LukeDev Users</Link>
							<p>This is where you can manage the users of LukeDev.net including:</p>
							<ul>
								<li>Delete Users</li>
								<li>Manually Confirm Users</li>
								<li>Approve users to use Lukedev.net</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-md-6 col-lg-6 col-xl-6 p-1">
						<div className="card p-2 h-100">
							<Link className="h4" to="/admin/manage-data/sections">Manage LukeDev Sections</Link>
							<p>This is where you can manage your sections (pages):</p>
							<ul>
								<li>Edit the section data</li>
								<li>Set parent pages</li>
								<li>Set urls</li>
								<li>Set page types</li>
								<li>Jump over to linked content</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-md-6 col-lg-6 col-xl-6 p-1">
						<div className="card p-2 h-100">
							<Link className="h4" to="/admin/manage-data/content">Manage LukeDev Content</Link>
							<p>This is where you can manage your content:</p>
							<ul>
								<li>Edit the content data</li>
								<li>Set the featured image</li>
								<li>Use JSON for your body parser to create content</li>
								<li>Add/Delete content</li>
							</ul>
						</div>
					</div>
					<div className="col-12 col-md-6 col-lg-6 col-xl-6 p-1">
						<div className="card p-2 h-100">
							<Link className="h4" to="/admin/manage-data/assets">Manage LukeDev Assets</Link>
							<p>This is where you upload and manage your assets (images, pdfs, whatever):</p>
							<ul>
								<li>Add/Delete Assets</li>
								<li>Edit alt text, rotation, and asset name</li>
								<li>View all assets</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AdminWelcome;