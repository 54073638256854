import React from "react";
import { Route, Switch } from "react-router-dom";
import { Component } from "react";
import PrivateRoute from "../components/common/PrivateRoute";
import ImageUploader from "../components/uploader/ImageUploader";
import Dummy from "../components/common/Dummy";

class UploadRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/uploadDummy" component={Dummy}></Route>
        <Switch>
          <PrivateRoute exact path="/uploader" component={ImageUploader} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default UploadRoutes;
