import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import { clearProfile } from '../actions/profileActions';

import { GET_ERRORS, SET_CURRENT_USER, GET_USERS,
				 CHANGE_AVATAR, UPDATE_USER_NAME} from './types';

//change avatar
export const changeAvatar = (url) => dispatch => {
	dispatch({
		type: CHANGE_AVATAR,
		payload: url
	})
}

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post('/api/users/register', userData)
    .then(res => history.push('/login'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login - Get User Token
export const loginUser = userData => dispatch => {
  axios
    .post('/api/users/login', userData)
    .then(res => {
      // Save to localStorage
      const { token } = res.data;
      // Set token to ls
      localStorage.setItem('jwtToken', token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
			const decoded = jwt_decode(token);
			localStorage.setItem("avatar", decoded.avatar)
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logoutUser = () => dispatch => {
	// remove token from localStorage
	localStorage.removeItem('jwtToken');
	//remove the auth header for future requests
	setAuthToken(false);
	// set current user to {} which is also set isAuthenticated to false
	dispatch(clearProfile());
	dispatch(setCurrentUser({}));

	//document.location.href = "./login";
};

export const getUsers = () => dispatch => {
  axios
    .get('/api/users')
    .then(res => {
			console.log(res);
			dispatch({
				type: GET_USERS,
				payload: res.data
			});
		})
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const changeName = (name) => dispatch => {
	axios
    .post('/api/users/changeName', {name: name})
    .then(res => {
			dispatch({
				type: UPDATE_USER_NAME,
				payload: name
			})
			console.log(res);
			axios
				.get('/api/users/refreshtoken')
				.then(res => {
					console.log(res);
					localStorage.setItem('jwtToken', res.data.token)
					const decoded = jwt_decode(localStorage.jwtToken)
					dispatch(setCurrentUser(decoded));
					
					
				})
				.catch(err =>{
					console.log(err);
					}
				);
		})
    .catch(err =>{
			console.log(err);
		}
	)
}

export const changeEmail = (name) => dispatch => {
	axios
    .post('/api/users/changeName', {name: name})
    .then(res => {
			dispatch({
				type: UPDATE_USER_NAME,
				payload: name
			})
			console.log(res);
			axios
				.get('/api/users/refreshtoken')
				.then(res => {
					console.log(res);
					localStorage.setItem('jwtToken', res.data.token)
					const decoded = jwt_decode(localStorage.jwtToken)
					dispatch(setCurrentUser(decoded));
					
					
				})
				.catch(err =>{
					console.log(err);
					}
				);
		})
    .catch(err =>{
			console.log(err);
		}
	)
}

export const refreshToken = () => dispatch => {
	axios
		.get('/api/users/refreshtoken')
		.then(res => {
			console.log(res);
			localStorage.setItem('jwtToken', res.data.token)
			const decoded = jwt_decode(localStorage.jwtToken)
			dispatch(setCurrentUser(decoded));
			
			
		})
		.catch(err =>{
			console.log(err);
			}
		);
}