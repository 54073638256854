import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

class ProfileGithub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: "a986434bf198cbd6d605",
      clientSecret: "ce037e243779e9240f5b0bc59697c9f4de03b2a2",
      count: 5,
      sort: "created: asc",
      repos: [],
    };

    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { username } = this.props;
    const { count, sort, clientId, clientSecret } = this.state;
    axios
      .get(
        `https://api.github.com/users/${username}/repos?per_page=${count}&sort=${sort}&client_id=${clientId}&client_secret=${clientSecret}`
      )
      .then((res) => res.json())
      .then((data) => {
        if (this.myRef) {
          this.setState({ repos: data });
        }
      })
      .catch((err) => console.warn(err));
  }

  render() {
    const { repos } = this.state;

    const repoItems = repos.map((repo) => (
      <div key={repo.id} className="card card-body mb-2">
        <div className="row">
          <div className="col-md-6">
            <h4>
              <a
                href={repo.html_url}
                className="text-info"
                target="_blank"
                rel="noopener noreferrer"
              >
                {repo.name}
              </a>
            </h4>
            <p>{repo.description}</p>
          </div>
          <div className="col-md-6">
            <span className="badge badge-info mr-1">
              Stars: {repo.stargazers_count}
            </span>
            <span className="badge badge-secondary mr-1">
              Watchers: {repo.watchers_count}
            </span>
            <span className="badge badge-success">
              Forks: {repo.forks_count}
            </span>
          </div>
        </div>
      </div>
    ));
    return (
      <div ref={this.myRef}>
        <hr />
        <h3 className="mb-4">Latest Github Repos</h3>
        {repoItems}
      </div>
    );
  }
}

ProfileGithub.propTypes = {
  username: PropTypes.string.isRequired,
};

export default ProfileGithub;
