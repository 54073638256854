import axios from "axios";
import { GET_ERRORS, CLEAR_ERRORS } from "./types";
import {
	ADD_POST, POST_LOADING, GET_POSTS, DELETE_POST, GET_POST

} from './postTypes';


//add post
export const addPost = postData => dispatch => {
	axios
		.post('/api/posts', postData)
		.then( res => {
			dispatch({
				type: ADD_POST,
				payload: res.data,
			});
			dispatch({
				type: GET_ERRORS,
				payload: {},
			})
		})
		.catch(err => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			})
		})
}

// Add Comment
export const addComment = (postId, commentData) => dispatch => {
  dispatch(clearErrors());
  axios
    .post(`/api/posts/comment/${postId}`, commentData)
    .then(res =>
      dispatch({
        type: GET_POST,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
		);
};

// Delete Comment
export const deleteComment = (postId, commentId) => dispatch => {
  axios
    .delete(`/api/posts/comment/${postId}/${commentId}`)
    .then(res =>
      dispatch({
        type: GET_POST,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//delete post
export const deletePost = id => dispatch => {
	if(window.confirm('Are you sure?  This cannot be undone!')) {
		axios
			.delete(`/api/posts/${id}`)
			.then( res => {
				dispatch({
					type: DELETE_POST,
					payload: id,
				});
				dispatch({
					type: GET_ERRORS,
					payload: {},
				});
			})
			.catch(err => {
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				})
			})
	}
}

// Add Like
export const likePost = id => dispatch => {
  axios
    .post(`/api/posts/like/${id}`)
    .then(res => dispatch(getPosts()))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Add Like
export const unlikePost = id => dispatch => {
  axios
    .post(`/api/posts/unlike/${id}`)
    .then(res => dispatch(getPosts()))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


//get posts
export const getPosts = postData => dispatch => {
	dispatch(setPostLoading());
	axios
		.get('/api/posts')
		.then( res => {
			dispatch({
				type: GET_POSTS,
				payload: res.data,
				
			});
		})
		.catch(err => {
			dispatch({
				type: GET_POSTS,
				payload: null,
			})
		})
}

//get posts
export const getPost = id => dispatch => {
	//dispatch(setPostLoading());
	axios
		.get(`/api/posts/${id}`)
		.then( res => {
			dispatch({
				type: GET_POST,
				payload: res.data,
				
			});
		})
		.catch(err => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			})
		})
}

//set loading state
export const setPostLoading = () =>
{
	return {
		type: POST_LOADING
	}
}

// Clear errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};