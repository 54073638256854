import React, { Component } from "react";

class PageHeader extends Component {
  render() {
    const currentInfoProp = this.props.currentInfo;
    let currentInfo;

    if (typeof currentInfoProp !== "undefined") {
      currentInfo = currentInfoProp;
    }

    return (
      <div className="category-header " style={{ width: "100%" }}>
        <div className="container">
          <div
            className="row pl-3 pr-3 mt-3 text-light pt-2 pb-1"
            style={{ backgroundColor: "#3c3c3c" }}
          >
            <div className="col-md-12 h2 text-center text-capitalize">
              {typeof currentInfo !== "undefined" ? currentInfo.slug : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageHeader;
