import React, { Component } from "react";
import { parseContent } from "../../utils/contentParser";

class CategoryHeader extends Component {
  render() {
    const currentInfoProp = this.props.currentInfo;
    let currentInfo;

    if (typeof currentInfoProp !== "undefined") {
      currentInfo = currentInfoProp;
    }

    let paragraphs;
    if (typeof currentInfo !== "undefined") {
      let contentItems = JSON.parse(currentInfo.description);
      paragraphs = contentItems.map((item, index) => {
        return parseContent(item, index);
      });
    }

    let text_paragraphs;
    if (typeof currentInfo !== "undefined") {
      if (currentInfo.text !== null) {
        let currentItems = JSON.parse(currentInfo.text);
        text_paragraphs = currentItems.map((item, index) => {
          return parseContent(item, index);
        });
      }
    }

    return (
      <div className="category-header bg-light" style={{ width: "100%" }}>
        <div className="container">
          <div className="row pl-3 pr-3 mt-3 text-dark pt-2 pb-3">
            <div className="col-md-12 h2 text-center text-capitalize">
              {typeof currentInfo !== "undefined" ? currentInfo.slug : null}
            </div>
            <div className="col-md-12 h4 text-center" ></div>
						<div style={{  fontSize: "1.3rem" }}>
							{typeof currentInfo !== "undefined" ? paragraphs : null}
							{typeof currentInfo !== "undefined" ? text_paragraphs : null}
						</div>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryHeader;
