import React from "react";
import { Route } from "react-router-dom";
import { Component } from "react";
//import PrivateRoute from '../components/common/PrivateRoute';
import ContentPage from "../components/content-page/ContentPage";

class ContentRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route
          exact
          path="/projects/content/:contentId"
          component={ContentPage}
        />
      </React.Fragment>
    );
  }
}

export default ContentRoutes;
