import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PostForm from "./PostForm";
import Spinner from "../../common/Spinner";
import { getPosts } from "../../../actions/postActions";
import PostFeed from "./PostFeed";

class Posts extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      posts: {},
      errors: {},
      loading: {},
    };
  }

  componentDidMount() {
    this.props.getPosts();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.post) {
      if (this.props.post.posts) {
        if (
          this.props.post.posts.length < nextProps.post.posts.length ||
          this.props.post.posts.length > nextProps.post.posts.length
        ) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  }

  render() {
    const { posts, loading } = this.props.post;
    let postsContent;
    if (posts === null || loading) {
      postsContent = <Spinner />;
    } else {
      postsContent = <PostFeed posts={posts} />;
    }

    return (
      <div className="feed">
        <div className="row">
          <div className="col-md-12">
            <PostForm />
            {postsContent}
          </div>
        </div>
      </div>
    );
  }
}

Posts.propTypes = {
  post: PropTypes.object.isRequired,
  getPosts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
  errors: state.errors,
  loading: state.loading,
});

export default connect(mapStateToProps, { getPosts })(Posts);
