import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { loginUser } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import axios from "axios";
class ForgotPassword extends Component {
	constructor() {
		super();
		this.state = {
			email: "",
			password: "",
			sent: false,
			errors: {},
		};

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.sendCode = this.sendCode.bind(this);
	}

	componentDidMount() {
		if(this.props.auth.isAuthenticated){
			this.props.history.push("/dashboard")
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if(this.props.auth !== prevProps.auth ) {
			if(this.props.auth.isAuthenticated){
				this.props.history.push('./dashboard');
			}
		}

		if(this.props.errors !== prevProps.errors) {
			this.setState({ errors: this.props.errors });
		}
	}

	sendCode(e){
		axios.post("/api/users/sendConfirmCode", {email: this.state.email})
			.then(res => {
				this.setState({sent: true});
			});
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value});
	}

	onSubmit(e){
		e.preventDefault();
		axios.post("/api/users/resetPasswordRequest", {email: this.state.email})
			.then(res => {
				this.setState({sent: true});
			});
	}
	
	render() {
		const {errors} = this.state;
		return (
			<div className="login">
				<div className="container">
					<div className="row">
						<div className="col-md-8 m-auto">
							<h1 className="display-4 text-center">Forgot Password</h1>
							<p className="lead text-center">
								Forgot your password?  Enter it below, and if you have an account, you will receive an email to reset it.
							</p>
							<form onSubmit={this.onSubmit}>
								{errors.apifailure && (<div className="text-danger">Connection to Login API failed Status: {errors.text}</div>) }
								{
									errors.confirmed && 
									(
										<div className="text-center">
											<div className="text-danger">{errors.confirmed}</div>
											<div className="h4">If you have not received your code, please click <div className="btn btn-info" onClick={this.sendCode}>Here</div></div>
										</div>
									) 
								}
								{
									this.state.sent && <div className="text-center h5 text-info">Email has been sent</div>
								}
								<TextFieldGroup
									name='email'
									type='email'
									value={this.state.email}
									onChange={this.onChange}
									error={errors.email}
								/>
								<input type="submit" className="btn btn-info btn-block mt-4" />
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ForgotPassword.propTypes = {
	loginUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(ForgotPassword);
