import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import Moment from "react-moment";
import { deleteExperience } from "../../actions/profileActions";

class Experience extends Component {
  onDeleteClick(id) {
    this.props.deleteExperience(id);
  }

  render() {
    const experience = this.props.experience.map((exp) => {
      if (exp.current === true) {
        exp.to = "Current";
      } else {
        exp.to = <Moment format="MM/YYYY">{exp.to}</Moment>;
      }
      return (
        <tr key={exp._id}>
          <td>{exp.company}</td>
          <td>{exp.title}</td>
          <td>
            <Moment format="MM/YYYY">{exp.from}</Moment> - {exp.to}
          </td>
          <td align="center">
            <Link
              to={`/edit-experience/${exp._id}`}
              className="btn btn-warning text-white mb-1 pl-3 pr-3"
            >
              Edit
            </Link>
            <span style={{ display: "inline-block", width: "3px" }}></span>
            <button
              onClick={this.onDeleteClick.bind(this, exp._id)}
              className="btn btn-danger mb-1 pr-2 pl-2"
            >
              Delete
            </button>
          </td>
        </tr>
      );
    });

    return (
      <div className="p-1" style={{ width: "100%", marginLeft: "0px" }}>
        <h3 className="ml-2 mb-4">Experience:</h3>
        <table id="experience-table" className="table">
          <thead>
            <tr>
              <th>Company</th>
              <th>Title</th>
              <th>Years</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{experience}</tbody>
        </table>
      </div>
    );
  }
}

Experience.propTypes = {
  deleteExperience: PropTypes.func.isRequired,
};
export default connect(null, { deleteExperience })(withRouter(Experience));
