import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import Moment from "react-moment";
import { deleteEducation } from "../../actions/profileActions";

class Education extends Component {
  onDeleteClick(id) {
    this.props.deleteEducation(id);
  }

  render() {
    const education = this.props.education.map((edu) => {
      if (edu.current === true) {
        edu.to = "Current";
      } else {
        edu.to = <Moment format="MM/YYYY">{edu.to}</Moment>;
      }
      return (
        <tr key={edu._id}>
          <td>{edu.school}</td>
          <td>{edu.degree}</td>
          <td>
            <Moment format="MM/YYYY">{edu.from}</Moment> - {edu.to}
          </td>
          <td align="center">
            <Link
              to={`/edit-education/${edu._id}`}
              className="btn btn-warning text-white mb-1 pl-3 pr-3"
            >
              Edit
            </Link>
            <span style={{ display: "inline-block", width: "3px" }}></span>
            <button
              onClick={this.onDeleteClick.bind(this, edu._id)}
              className="btn btn-danger mb-1 pr-2 pl-2"
            >
              Delete
            </button>
          </td>
        </tr>
      );
    });

    return (
      <div className="p-1" style={{ width: "100%", marginLeft: "0px" }}>
        <h3 className="ml-2 mb-4">Education: </h3>
        <table id="education-table" className="table">
          <thead>
            <tr>
              <th>School</th>
              <th>Degree</th>
              <th>Years</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{education}</tbody>
        </table>
      </div>
    );
  }
}

Education.propTypes = {
  deleteEducation: PropTypes.func.isRequired,
};

export default connect(null, { deleteEducation })(withRouter(Education));
