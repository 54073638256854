import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUsers } from "../../actions/authActions";
import {
  getFriends,
  addFriend,
  removeFriend,
} from "../../actions/socialActions";
import TextFieldGroup from "../common/TextFieldGroup";
import axios from "axios";
import Spinner from "../common/Spinner";

class Friends extends Component {
  constructor() {
    super();
    this.state = {
      search: "",
      password: "",
      errors: {},
      users: [],
      social: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.addFriend = this.addFriend.bind(this);
    this.removeFriend = this.removeFriend.bind(this);
  }

  componentDidMount() {
    axios
      .get("/api/users")
      .then((users) => {
        this.setState({ users: users.data });
      })
      .catch((err) => console.warn(err));
    this.props.getFriends();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.users !== this.state.users &&
      this.state.users &&
      this.state.users.length > 0
    ) {
      return true;
    }
    return false;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.length >= 3) {
      axios
        .post("/api/users/find", { search: e.target.value })
        .then((users) => {
          this.setState({ users: users.data });
        })
        .catch((err) => console.warn(err));
    } else {
      axios
        .get("/api/users")
        .then((users) => {
          this.setState({ users: users.data });
        })
        .catch((err) => console.warn(err));
    }
  }

  onClick(e) {}

  onSubmit(e) {
    e.preventDefault();
  }

  addFriend(e) {
    this.props.addFriend(e.target.getAttribute("userid"));
  }

  removeFriend(e) {
    this.props.removeFriend(e.target.getAttribute("userid"));
  }

  render() {
    const { errors } = this.state;
    let users;
    if (
      this.state.users &&
      this.state.users.length > 0 &&
      this.props.social &&
      this.props.social.friends
    ) {
      users = this.state.users.map((user) => {
        if (
          user._id === this.props.auth.user.id ||
          typeof this.props.auth.user.id === "undefined"
        ) {
          return null;
        }
        return (
          <div
            key={user._id}
            className="card p-2 col text-center m-1"
            style={{ minWidth: "300px", maxWidth: "400px" }}
          >
            <div>
              <img
                className="img-fluid"
                src={
                  user.avatar.indexOf("gravatar") < 0
                    ? user.avatar.split(".").slice(0, -1).join(".") + "_md.jpg"
                    : user.avatar
                }
                alt={user.name}
                style={{ maxHeight: "200px", width: "auto" }}
              />
            </div>
            {this.props.social.friends.filter(
              (friend) => user._id === friend.userId
            ).length > 0 ? (
              <div
                className="h4 text-success bg-light"
                style={{
                  width: "100px",
                  margin: "0 auto",
                  marginTop: "-35px",
                  marginBottom: "7px",
                }}
              >
                Friends
              </div>
            ) : null}

            <div
              to={`/social/profiles/${user._id}`}
              className="mb-2 mt-1 h4 text-center text-capitalize"
            >
              {user.name}
            </div>

            {this.props.social.friends.filter(
              (friend) => user._id === friend.userId
            ).length > 0 ? (
              <div
                userid={user._id}
                onClick={this.removeFriend}
                className="btn btn-danger"
              >
                Unfriend
              </div>
            ) : (
              <div
                userid={user._id}
                onClick={this.addFriend}
                className="btn btn-success "
              >
                Add Friend
              </div>
            )}
          </div>
        );
      });
    }

    return (
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">Friends</h1>
              <p className="lead text-center">Find your friends</p>
              <form onSubmit={this.onSubmit}>
                {errors.apifailure && (
                  <div className="text-danger">
                    Connection to Login API failed Status: {errors.text}
                  </div>
                )}
                <TextFieldGroup
                  name="search"
                  type="text"
                  value={this.state.search}
                  onChange={this.onChange}
                  error={errors.search}
                  placeholder="find user"
                />
              </form>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col md-12">
              <div className="row">
                {users}
                {!users ? (
                  <Fragment>
                    {" "}
                    <div className="text-center" style={{ margin: "0 auto" }}>
                      <Spinner />
                    </div>
                    <div className="col col-md-12 text-center h3">
                      No users with that name or handle found
                    </div>
                  </Fragment>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Friends.propTypes = {
  getFriends: PropTypes.func.isRequired,
  addFriend: PropTypes.func.isRequired,
  removeFriend: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  social: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  social: state.social,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getUsers,
  addFriend,
  getFriends,
  removeFriend,
})(Friends);
