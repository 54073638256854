import { ADD_FRIEND, REMOVE_FRIEND, GET_FRIENDS } from "../actions/socialTypes";

const initialState = {
  isAuthenticated: false,
  friends: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_FRIEND:
      return {
        ...state,
        friends: action.payload,
      };

    case REMOVE_FRIEND:
      return {
        ...state,
        friends: action.payload,
      };
    case GET_FRIENDS:
      return {
        ...state,
        friends: action.payload,
      };

    default:
      return state;
  }
}
