import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import isEmpty from "../../../validation/is-empty";
import ImageLoader from "../../common/ImageLoader";

class ProfileItem extends Component {
  render() {
    const { profile } = this.props;
    let skills;
    if (profile.skills) {
      skills = profile.skills.map((skill, index) => (
        <span
          key={index}
          className="btn btn-dark d-inline-block mr-2 mb-1 p-1 pr-2 pl-2 text-capitalize"
        >
          <i className="fa fa-check bg-light mr-1 text-dark "></i>
          {skill}
        </span>
      ));
    }

    return (
      <div className="card card-body bg-white mb-1">
        <div className="row">
          <Link
            to={`/social/profiles/${profile.handle}`}
            className="col-12 col-lg-4  col-md-4"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ImageLoader
              src={
                profile.user
                  ? profile.user.avatar
                  : "//www.gravatar.com/avatar/57efbee2d5b4f2bdc6726c5a59f433b4?s=200&amp;r=pg&amp;d=mm"
							}
							size={profile.user ? "md" : "orig"}
              alt=""
              className=""
            />
          </Link>
          <div className="col-12 col-sm-8 col-lg-8 ">
            <Link
              to={`/social/profiles/${profile.handle}`}
              className="h3 text-capitalize"
            >
              {profile.user && profile.user.name}
            </Link>
            <p>
              {profile.status}{" "}
              {isEmpty(profile.company) ? null : (
                <span>at {profile.company}</span>
              )}
            </p>
            <p>
              {isEmpty(profile.location) ? null : (
                <span>
                  <b>Location:</b> {profile.location}
                </span>
              )}
            </p>
            <div className="mb-3">
              {isEmpty(profile.bio) ? null : (
                <div>
                  <div>
                    <b>
                      <u>Short Bio:</u>
                    </b>
                  </div>
                  <span>{profile.bio.substring(0, 300) + "..."}</span>
								</div>
								
							)}
							<div className="mt-3">
								{skills}
							</div>
            </div>
            <Link
              to={`/profile/${profile.handle}`}
              className="btn btn-info  text-white"
            >
              View Profile
            </Link>
          </div>
          {/*<div className="col-4 col-md-4 col-lg-4 d-none d-md-block">
            <h4>Skill Set</h4>
            <div className="">{skills}</div>
							</div>*/}
        </div>
      </div>
    );
  }
}

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileItem;
