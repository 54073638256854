import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ProfileHeader from "./ProfileHeader";
import ProfileAbout from "./ProfileAbout";
import ProfileCreds from "./ProfileCreds";
import ProfileGithub from "./ProfileGithub";
import Spinner from "../../common/Spinner";
import { getProfileByHandle } from "../../../actions/profileActions";
import RedditModule from "../../common/modules/Reddit";
import ProjectsModule from "../../common/ProjectsModule";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.username = React.createRef();
    this.newsWidget = React.createRef();
  }

  componentDidMount() {
    if (this.props.match.params.handle) {
      this.props.getProfileByHandle(this.props.match.params.handle);
    }
  }

  onLoad(e) {
    let iFrame = e.target;

    if (e.target.contentWindow) {
      iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
    }
  }

  render() {
    const { profile, loading } = this.props.profile;
    let profileContent;
    if (profile === null || loading) {
      profileContent = <Spinner />;
    } else {
      this.username = profile.githubusername;
      profileContent = (
        <Fragment>
          <div
            id="profile-container"
            className="row"
            style={{ marginTop: "-10px" }}
          >
            <div className="col col-12 col-lg-8">
              <ProfileHeader profile={profile} />
              <ProfileAbout profile={profile} />
              <ProfileCreds profile={profile} />
              {profile && profile.githubusername ? (
                <ProfileGithub username={this.username} />
              ) : (
                ""
              )}
            </div>
            <div className="col col-12 col-lg-4">
              <iframe
                scrolling="no"
                title="news-widget"
                id="widget"
                className="card mb-2"
                src="/public/widget.html?gfd=71"
                style={{ width: "100%", scroll: "none" }}
              ></iframe>
              <ProjectsModule />
              <RedditModule stream="linux" />
            </div>
          </div>
        </Fragment>
      );
    }
    return <Fragment>{profileContent}</Fragment>;
  }
}

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
  getProfileByHandle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfileByHandle })(Profile);
