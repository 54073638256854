import React from "react";
import { Route, Switch } from "react-router-dom";
import { Component } from "react";
import PrivateRoute from "../components/common/PrivateRoute";
import Posts from "../components/social/posts/Posts";
import Post from "../components/social/post/Post";
import Profiles from "../components/social/profiles/Profiles";
import Profile from "../components/social/profile/Profile";
import Friends from "../components/social/Friends";

class SocialRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container user-pages">
          <Switch>
            <PrivateRoute exact path="/social/friends" component={Friends} />
            <PrivateRoute exact path="/social/posts" component={Posts} />
            <PrivateRoute exact path="/social/post/:id" component={Post} />
          </Switch>
          <Route exact path="/social/profiles/" component={Profiles} />
          <Route exact path="/social/profiles/:handle" component={Profile} />
        </div>
      </React.Fragment>
    );
  }
}

export default SocialRoutes;
