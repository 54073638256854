import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const PrivateRoute = ({ component: Component, auth, accessLevel, ...rest }) => {
  if (accessLevel) {
    if (auth.user.role === "admin" || auth.user.role <= accessLevel) {
      return (
        <Route
          {...rest}
          render={(props) =>
            auth.isAuthenticated === true ? (
              <Component {...props} />
            ) : (
              <div className="text-warning text-center mt-4 h1">
                You are not authorized for this page
              </div>
            )
          }
        />
      );
    } else {
      return (
        <div className="text-danger text-center mt-4 h1">
          You are not authorized to view this page
        </div>
      );
    }
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          auth.isAuthenticated === true ? (
            <Component {...props} />
          ) : (
            (document.location.href = "/login")
          )
        }
      />
    );
  }
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(PrivateRoute);
