import React, { Component } from "react";
import Spinner from "../../common/Spinner";
import isEmpty from "../../../validation/is-empty";

class ProfileAbout extends Component {
  render() {
    const { profile } = this.props;
    if (profile === null || profile.loading) {
      return <Spinner></Spinner>;
    }

    const skills = profile.skills.map((skill, index) => (
      <span
          key={index}
          className="btn btn-dark d-inline-block mr-1 mb-1 p-1 pr-2 pl-2 text-capitalize"
        >
				<i className="fa fa-check bg-light mr-1 text-dark "></i>
				{skill}
			</span>
    ));
    let bio = profile.bio.replace(/•/g, "").split(/(?:\r\n|\r|\n)/g);
    bio = bio.map((line, i) => (
      <p key={i} className="">
        {line}
      </p>
    ));
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card card-body  mb-3">
            <h3 className="text-info">Skills</h3>
            <div
              id="skills-container"
              className="row d-flex pl-3 pr-3 mb-3 mt-2"
            >
              {skills}
            </div>
            <h3 className="text-info mb-3">{profile.user.name} Bio</h3>
            <div className="">{!isEmpty(profile.bio) ? bio : null}</div>
            <hr />
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileAbout;
