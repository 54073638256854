import React, { Component } from "react"; 
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import TextFieldGroup from '../../../../components/common/TextFieldGroup'
//import TextAreaFieldGroup from '../../common/TextAreaFieldGroup'
//import InputGroup from '../../common/InputGroup';
// import SelectListGroup from '../../common/SelectListGroup';
import {createGame} from '../../../../actions/farkleActions';

class CreateGame extends Component {
	constructor(props){
		super(props);
		this.state = {
			name: '',
			errors: {}
		  };

		  this.onChange = this.onChange.bind(this);
		  this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if(this.props.errors !== prevProps.errors) {
			this.setState({ errors: this.props.errors });
		}
		else{

		}
	}

	onSubmit(e){
		e.preventDefault();
		const gameData = {
			name: this.state.name,
		};

		this.props.createGame(gameData, this.props.history);
	}

	onChange(e){
		this.setState({[e.target.name]: e.target.value});
	}

	

	render() {
		const { errors } = this.state;



		return (
			<div className="create-game">
				<div className="container">
					<div className="row">
						<div className="col-md-8 m-auto">
							<h1 className="display-4 text-center">Create A New Game Session</h1>
							<p className="lead text-center">
								Let's get some information to get your game started!
							</p>
							<small className="d-block pb-3">* = required fields</small>

							<form onSubmit={this.onSubmit}>
								<TextFieldGroup
									placeholder="* Game Name"
									name="name"
									value={this.state.name}
									onChange={this.onChange}
									error={errors.name}
									info="A unique name for your game"
								/>
								
								<input type="submit" value="Submit" className="btn btn-info btn-block mt-4"/>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreateGame.propTypes = {
	// game: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
	game: state.game,
	errors: state.errors,
})

export default connect(mapStateToProps, { createGame} )(CreateGame);