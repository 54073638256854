import React from "react";
import { Route, Switch } from "react-router-dom";
import { Component } from "react";
import PrivateRoute from "../components/common/PrivateRoute";
import PetalLanding from "../components/PETAL/PetalLanding";
import Dashboard from "../components/dashboard/Dashboard";

class PetalRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/petal" component={PetalLanding} />
        <Switch>
          <PrivateRoute exact path="/petal/dashboard" component={Dashboard} />
        </Switch>
      </React.Fragment>
    );
  }
}

export default PetalRoutes;
