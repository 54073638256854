import React, { Component } from "react";
import { Link } from "react-router-dom";
import { parseContent } from "../../../utils/contentParser";
class HalfWidthCard extends Component {
  render() {
    let card = this.props.card;
    let descJSON = JSON.parse(card.Content.description);
    let description = descJSON.map((item, index) => {
      return parseContent(item, index);
    });

    return (
      <div key={card.id} className="col col-12 col-lg-12 col-xl-6 mb-3 ">
        <div className="card p-2 h-100 bg-white">
          <Link
            to={card.url}
            className="underline h3 text-info text-center text-capitalize"
          >
            {card.Content.title.replace(/_/g, " ")}
          </Link>
          <div className="h5 text-center">{card.Content.slug}</div>
          <div className="mt-3">{description}</div>
        </div>
      </div>
    );
  }
}

export default HalfWidthCard;
