import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

class ConfirmEmail extends Component {
	constructor() {
		super();
		this.state = {
			confirmed: false,
			email:'',
			errors: {},
		};

	}


	componentDidMount() {
		let email = decodeURIComponent(this.props.match.params.email);
		let ccode = this.props.match.params.ccode;
		axios.post(`/api/users/confirm`, {email: email, confirmCode: ccode})
			.then(res => {
				this.setState({confirmed: true});
			})
			.catch(err => console.warn(err));
	};

	render() {
		
		return (
			<div className="confirm-email">
				<div className="container">
					<div className="row">
						<div className="col-md-8 m-auto">
							<h1 className="display-4 text-center">Confirm Email</h1>
							<p className="lead text-center">{this.state.confirmed ? 
								(
									<div class="h5">Your email has been confirmed, please click <Link to="/login">here to login</Link></div>
								)
								: "This code is invalid"}</p>
						</div>
					</div>
				</div>
			</div>
		);
		
	}

}


export default ConfirmEmail;
