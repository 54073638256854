import React, { Fragment, useState } from "react";
import axios from "axios";

import Message from "./Message";
import Progress from "./Progress";

import { changeAvatar } from "../../actions/authActions";

const ImageUploader = () => {
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("Choose File");
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [overwrite, setOverwrite] = useState(false);

  const onChange = (e) => {
    if (e.target.name === "file") {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
    if (e.target.name === "overwrite") {
      if (e.target.checked === true) {
        setOverwrite(true);
      } else {
        setOverwrite(false);
      }
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("file", file);
    if (!file) {
      setMessage("No File Selected");
      return;
    }
    let params = "?uploadType=profilePicture";
    if (overwrite === true) {
      params += "&overwrite=true";
    }
    try {
      const res = await axios.post(
        "/api/uploader/profilePicture" + params,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            setUploadPercentage(
              parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            );

            // Clear percentage
            setTimeout(() => setUploadPercentage(0), 10000);
          },
        }
      );

      const { fileName, filePath } = res.data;

      setUploadedFile({ fileName, filePath });

      setMessage("File Uploaded");
      changeAvatar(filePath);
    } catch (err) {
      if (err.response.status === 500) {
        setMessage("There was a problem with the server");
      } else {
        setMessage(err.response.data.msg);
        if (err.response.data.msg === "File already exists on server.") {
          setUploadPercentage(0);
        }
      }
    }
  };
  return (
    <Fragment>
      <div className="container">
        {message ? <Message msg={message} /> : null}

        <form
          onSubmit={onSubmit}
          style={{ maxWidth: "600px", margin: "0 auto" }}
        >
          <h2>Upload your profile picture</h2>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              name="file"
              id="customFile"
              onChange={onChange}
            />
            <label className="custom-file-label">{fileName}</label>
          </div>

          <input
            type="submit"
            value="upload"
            className="btn btn-primary btn-block mt-4"
          ></input>
          <Progress percentage={uploadPercentage} />
        </form>
        <center>
          <input
            id="upload-overwrite"
            onChange={onChange}
            type="checkbox"
            name="overwrite"
          ></input>{" "}
          Overwrite?
        </center>

        {/* Show uploaded file* */}
        {uploadedFile ? (
          <div className="row mt-5">
            <div className="col-md-6 m-auto">
              <h3 className="text-center">{uploadedFile.fileName}</h3>
              <img
                style={{ width: "100%" }}
                src={uploadedFile.filePath}
                alt=""
              />
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default ImageUploader;
