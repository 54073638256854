import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaFieldGroup from "../../common/TextAreaFieldGroup";
import {
  getCurrentProfile,
  deleteExperience,
  addExperience,
} from "../../../actions/profileActions";
import isEmpty from "../../../validation/is-empty";
import { Link, withRouter } from "react-router-dom";
import { formatDate } from "../../../utils/commonTools";

class EditExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: "",
      title: "",
      location: "",
      from: "",
      to: "",
      current: false,
      description: "",
      errors: {},
      disabled: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  convert(data) {
    if (!data) {
      return null;
    }
    var profileData = data;
    const skillsCSV = data.skills;
    const arr = Object.values(skillsCSV);
    profileData.skills = arr;

    return data;
  }

  componentDidMount() {
    this.props.getCurrentProfile();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    }

    if (
      (!isEmpty(this.props.profile) || this.props.profile !== null) &&
      this.props.profile.loading === false &&
      this.props.profile !== prevProps.profile
    ) {
      let experience = this.props.profile.profile.experience;
      experience = experience.filter(
        (exp) => exp._id === this.props.match.params.exp_id
      )[0];
      if (experience === []) {
        return;
      }
      experience.description = experience.description.trim();
      experience.errors = {};
      experience.from = formatDate(experience.from);
      experience.to = formatDate(experience.to);
      experience.current = Boolean(experience.current);
      this.setState(experience);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let data = this.state;
    this.props.addExperience(data, this.props.history, true);
  }

  onChange(e) {
    if (e.target.name === "current") {
      this.setState({
        disabled: !this.state.disabled,
        current: !this.state.current,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  //select options for status
  statusOptions = [
    { label: "* Select Professional Status", value: 0 },
    { label: "Developer", value: "Developer" },
    { label: "Junior Developer", value: "Junior Developer" },
    { label: "Senior Developer", value: "Senior Developer" },
    { label: "Manager", value: "Manager" },
    { label: "Student or Learning", value: "Student or Learning" },
    { label: "Instructor or Teacher", value: "Instructor or Teacher" },
    { label: "Intern", value: "Intern" },
    { label: "Other", value: "Other" },
  ];

  render() {
    const { errors } = this.state;
    return (
      <div className="add-experience">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <Link to="/dashboard" className="btn btn-light">
                Go Back
              </Link>
              <h1 className="display-4 text-center">Edit Experience</h1>
              <p className="lead text-center">
                Edit this job or position that you have had in the past or
                currently
              </p>
              <small className="d-block pb3">* = required fields</small>

              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  placeholder="* Company"
                  name="company"
                  value={this.state.company}
                  onChange={this.onChange}
                  error={errors.company}
                />
                <TextFieldGroup
                  placeholder="* Job Title"
                  name="title"
                  value={this.state.title}
                  onChange={this.onChange}
                  error={errors.title}
                />
                <TextFieldGroup
                  placeholder="* Job Location"
                  name="location"
                  value={this.state.location}
                  onChange={this.onChange}
                  error={errors.location}
                />
                <h6>From Date</h6>
                <TextFieldGroup
                  name="from"
                  type="date"
                  value={this.state.from}
                  onChange={this.onChange}
                  error={errors.from}
                />
                <h6>From Date</h6>
                <TextFieldGroup
                  name="to"
                  type="date"
                  value={this.state.to}
                  onChange={this.onChange}
                  error={errors.to}
                  disabled={this.state.disabled ? "disabled" : ""}
                />
                <div className="form-check mb-4">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="current"
                    value={this.state.current}
                    onChange={this.onChange}
                    checked={this.state.current}
                    id="current"
                  />
                  <label htmlFor="current" className="form-check-label">
                    Current Position
                  </label>
                </div>
                <TextAreaFieldGroup
                  placeholder="Job Description"
                  name="description"
                  value={this.state.description}
                  onChange={this.onChange}
                  error={errors.description}
                />
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-info btn-block mt4"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditExperience.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  addExperience: PropTypes.func.isRequired,
  deleteExperience: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  deleteExperience,
  addExperience,
  getCurrentProfile,
})(withRouter(EditExperience));
