import React, { Component } from "react"; 
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class FarkleLanding extends Component {

	componentDidMount() {
		// if(this.props.auth.isAuthenticated){
		// 	this.props.history.push("/dashboard") 
		// }
	};
	
	render() {
		return (
			<div className="landing">
				<div className="dark-overlay text-light pb-4">
					<div className="container mt-3 mb-1">
						<div className="row">
							<div className="col-md-12 text-center">
								<div className="h2 mt-4">
									Welcome to the LukeDev.net Portal!
								</div>
								
							</div>
						</div>
						
					</div>
					<div className="bg-light pb-4" style={{ width: "100%" }}>
						<div className="container">
					
						
							<div className="row pl-3 pr-3 mt-3 text-dark pt-2">
								
								<div className="col-md-12 h2 text-center">
									Farkle Dice Game
								</div>
								<div className="col-md-12 text-center">
									<Link to='/projects/games/farkle/newGame' className="mr-3">
									<div className=" btn btn-success">Start Game</div>
									</Link>	
									<Link to='/projects/games/farkle/showAll' className="mr-3">
									<div className="btn btn-dark">View Available Games</div>
									</Link>	
								</div>
								
								<div className="col-md-12 h4 text-center"></div>
								<p>Farkle is a game that many have played since they were kids.  Here is a score/game tracking system you can use to play with friends!</p>

								<p>For added fun, you can put in a link for video chats or specify a service that everyone should connect to outside of this site.</p>

							
								
								<div className="farkle-body">
									
									<div className="h3 text-center">General Gameplay:</div>
									<p>The objective is to react 10,000 points.  To get there, there are mainly two sets of rules that people play with:</p>
									
									<p>A player rolls 6 dice.  If there is any score gained, those dice can be set aside and the player can roll again.  If more points are acquired, that is added to this turn.  Again, a player may move the additional dice (which gained them points) to the side. If there are no more points acquired, all points for this turn are lost and gameplay moves to the next player.  A player can choose when and when NOT to roll the remaining dice and just take the points they have acquired</p>
								</div>
								
								
								<div className="farkle-body col col-md-12">
									<div className="h3 text-center">Rule Set 1 (points):</div>
									<div className=" text-center">
										<img className="text-center" src="/images/farkle/ruleset1.png" style={{maxWidth: "500px"}} alt="Rule Set 1"/>
									</div>

									<div className="h3 text-center mt-2">Rule Set 2 (points):</div>
									<div className=" text-center">
										<img className="text-center" src="/images/farkle/ruleset2-3.png" style={{maxWidth: "500px"}} alt="Rule Set 2"/>
									</div>
								</div>	
								
								
							</div>
						</div>
					</div>	
				</div>
		</div>
	);
  }
}

FarkleLanding.propTypes = {
	auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
	auth: state.auth,
})

export default connect(mapStateToProps)(FarkleLanding);