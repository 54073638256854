import React, { Component } from "react"; 
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { getAllGames } from '../../../../actions/farkleActions';


class ShowAllGames extends Component {
	constructor(props){
		super(props);
		this.state = {
			farkle: {}
		}
	}

	componentDidMount(){
		this.props.getAllGames();
	}

	

	render() {
		let allGames;
		
		
		if(this.props.farkle && this.props.farkle.length > 0)
		{
			allGames = this.props.farkle.map(game => {
				let admin;
				if(game.user === this.props.auth.user.id){
					admin = (
						<React.Fragment>
							<Link to={`/projects/games/farkle/edit/${game._id}`} className="btn btn-warning ml-1 mt-xs-2" style={{ marginTop: "-7px" }}>Edit </Link>
							<Link to={`/projects/games/farkle/view/${game._id}`} className="btn btn-danger ml-1 mt-xs-2" style={{ marginTop: "-7px" }}>Delete</Link>
						</React.Fragment>
					)
				}
				return (
						<div key={game._id} className="game-card view overlay border-dark border-bottom p-2 pb-1" style={{ height: "" }}>
							<div className="row">
								<div className="col col-md-12 pb-1">
									
									<Link to={`/projects/games/farkle/view/${game._id}`} className="h3 mr-3 pb-1 mt-1 mt-xs-2" style={{ position:"relative",top:"6px" }}>{game.name}</Link>
									<div className="float-sm-right d-inline-block mt-1">
										{admin}
										<Link to={`/projects/games/farkle/view/${game._id}`} className="btn btn-success ml-1 mt-xs-2" style={{ marginTop: "-2px" }}>View</Link>
										
									</div>
								</div>
								
							</div>
							
							
						</div>
				)
			});
		}
		

		return (
			<div className="landing category-landing">
				
				<div className="dark-overlay text-light pb-2">
					{/*--------------Title Bar ----------------*/}
					<div className="container mt-3 mb-1">
						<div className="row">
							<div className="col-md-12 text-center">
								<div onClick={this.props.history.goBack} className="btn btn-secondary btn-md mt-3 float-left" style={{ position: "relative", top: "3px" }}>&#x2B05; Back</div>
								<div className="h2 mt-3 text-capitalize">	
									Farkle
								</div>
								<Link to={`/projects/games/farkle/addFriends`} className="float-right btn btn-success ml-3" style={{ marginTop: "-2px" }}>Add Friends</Link>
								<Link to={`/projects/games/farkle/viewFriends`} className="float-right btn btn-success ml-3" style={{ marginTop: "-2px" }}>View Friends</Link>
								
							</div>
						</div>
					</div>

					<div className="category-header bg-light pb-3" style={{ width: "100%" }}>
						<div className="container">
							<div className="row pl-3 pr-3 mt-3 text-dark pt-2">
								<div className="col-md-12 h2 text-center text-capitalize">All Games</div>
								<div className="col-md-12 h4 text-left">{allGames}</div>
								
								
							</div>
						</div>
					</div>

				</div>
		</div>
		);
	}
}

ShowAllGames.propTypes = {
	farkle: PropTypes.array,
	auth: PropTypes.object.isRequired,
	getAllGames: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	farkle: state.games.farkle,
	auth: state.auth,
});

export default connect(mapStateToProps, {getAllGames})(ShowAllGames);