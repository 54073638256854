import React, { Component } from 'react'
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import {Link } from "react-router-dom";
import {editGame, getGame, saveGame, addUserToGame} from "../../../../../actions/farkleActions";
import {getUsers} from "../../../../../actions/authActions";
import isEmpty from '../../../../../validation/is-empty';

class EditGame extends Component {
	constructor(props){
		super(props);
		this.state = {
			game: {}
		}
	}
	
	componentDidMount(){
		this.props.getGame(this.props.match.params.id);
		this.props.getUsers();
	}
	
	onAddClick(uid){
		this.props.addUserToGame(this.props.match.params.id, uid)
	}

	render() {
		if(typeof this.props.games === "undefined" && this.props.games !== {})
		{
			return( <div className="h3">Loading</div>)
		}
		else{
			var game = this.props.games.game;
			let user = this.props.auth.user;
			let players = this.props.auth.players;
			let currentPlayers;
			
			if(game.players)
			{
				currentPlayers = game.players.map(player => {
					return (
						<li key={player._id} className="mb-3" style={{ height: "20px" }}>{player.name} 
							<div onClick={this.onDeleteClick} className="ml-3 d-inline btn btn-warning">Remove Player</div>
						</li>
					);
				}
			);
		}
		
			let availablePlayers;
			if(!isEmpty(players)){
				
				game.players.map(gp => {
					
					players = players.filter(pl => {
						return pl._id !== gp.id && pl._id !== user.id
					});
					return null;
					
				});
				// game.players.each(player => {
				// 	players = player.filter(pl => {
				// 		return pl.id !== player.id
				// 	});
				// });
				availablePlayers = players.map(player => {
					return (
							<li key={player.user} className="mb-3">{player.name} 
								<div onClick={this.onAddClick(player.user)} className="ml-3 d-inline btn btn-warning">Add Player</div>
							</li>
					);
				});
			}

			if(game.user !== user.id)
			{
				return ( <div className="h3">You are not authorized to edit this game</div>)
			}
			else{
				return (
					<div className="container">
						<div className="h2">Edit {game.name}</div>
						<div>
							<div className="h4">Current Players:</div>
							<ul>
								{currentPlayers}
							</ul>
							<div className="h4">Available Players:</div>
							<ul>
								{availablePlayers}
							</ul>
						</div>
						<div className="btn btn-success">Start Game</div>
					</div>
				)	
			}
				
		}
		
	}
}

EditGame.propTypes = {
	game: PropTypes.object,
	auth: PropTypes.object.isRequired,
	editGame: PropTypes.func.isRequired,
	getUsers: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	games: state.games,
	auth: state.auth
})

export default connect(mapStateToProps, { editGame, getGame, saveGame, getUsers, addUserToGame } )(EditGame);