import axios from 'axios';
import {GET_ERRORS} from './types';
import { GET_FRIENDS, ADD_FRIEND, REMOVE_FRIEND } from './socialTypes';

export const addFriend = (userid) => dispatch => {
	console.log(userid);
	axios
    .post('/api/users/friends/', {userId: userid})
    .then(res => {
			dispatch({
				type: ADD_FRIEND,
				payload: res.data
			});
		})
    .catch(err =>{
			console.log(err);
		}
    );
}

export const removeFriend = (userid) => dispatch => {
	console.log(userid);
	axios
    .delete('/api/users/friends/', { data: {userId: userid}} )
    .then(res => {
			console.log(res);
			dispatch({
				type: REMOVE_FRIEND,
				payload: res.data
			});
		})
    .catch(err => {
			console.log(err)
		}
    );
}

export const getFriends = () => dispatch => {
	axios
    .get('/api/users/friends/')
    .then(res => {
			dispatch({
				type: GET_FRIENDS,
				payload: res.data
			});
		})
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
}
