import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import TextFieldGroup from "../../common/TextFieldGroup";
import Spinner from "../../common/Spinner";
import ImageLoader from "../../common/ImageLoader";

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      search: "",
      users: [],
      page: 1,
      count: 0,
      limit: 10,
    };
    this.onChange = this.onChange.bind(this);
    this.changePage = this.changePage.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  changePage(e) {
    let direction = e.target.getAttribute("value");
    let page = this.state.page;
    if (direction === "up") {
      this.setState({ page: page + 1 });
      this.getUsers(page + 1);
    } else {
      this.setState({ page: page - 1 });
      this.getUsers(page - 1);
    }
  }

  getUsers(page) {
    axios
      .post("/api/users/detailedUsers", { page: page, limit: this.state.limit })
      .then((res) => {
        this.setState({ users: res.data });
      });
  }

  getCount() {
    axios.post("/api/users/detailedUsers", { count: true }).then((res) => {
      this.setState({ count: res.data.count });
    });
  }

  componentDidMount() {
    this.getUsers(1);
    this.getCount();
  }

  findUsersByString(str) {
    axios
      .post("/api/users/search", { search: str })
      .then((res) => {
        this.setState({ users: res.data });
      })
      .catch((err) => {
        console.warn(err);
        this.setState({ errors: err });
      });
  }

  onChange(e) {
    if (e.target.name === "search") {
      this.setState({ [e.target.name]: e.target.value });
      this.findUsersByString(e.target.value);
    }

    if (e.target.name === "confirmed" || e.target.name === "approved") {
      let apif;
      if (e.target.name === "confirmed") {
        apif = "confirm";
      }
      if (e.target.name === "approved") {
        apif = "approve";
      }

      let api = `/api/users/admin/${apif}`;

      if (true) {
        axios
          .post(api, {
            userId: e.target.value,
            value: e.target.checked,
          })
          .then((res) => {})
          .catch((err) => {
            console.warn(err);
          });
      }
      e.target.checked = !e.target.checked;
    }
  }

  onClick(e) {
    let name = e.target.getAttribute("name");
    let value = e.target.getAttribute("value");
    if (name === "search") {
      this.setState({ [name]: value });
      this.findUsersByString(value);
    }
    if (name === "confirmed" || name === "approved") {
      let apif;
      if (name === "confirmed") {
        apif = "confirm";
      }
      if (name === "approved") {
        apif = "approve";
      }

      let api = `/api/users/admin/${apif}`;
      let checked = e.target.getAttribute("ischecked") === "true";

      if (true) {
        axios
          .post(api, {
            userId: value,
            value: !checked,
          })
          .then((res) => {})
          .catch((err) => {
            console.warn(err);
          });
      }
      e.target.classList.remove("fa-check-square");
      e.target.classList.remove("fa-square");
      if (checked) {
        e.target.classList.add("fa-square");
        e.target.setAttribute("ischecked", "false");
      } else {
        e.target.classList.add("fa-check-square");
        e.target.setAttribute("ischecked", "true");
      }
    }
  }

  deleteUser(e) {
    if (
      window.confirm(
        "Are you sure you want to delete this user and their profile?!?  This cannot be undone!"
      )
    ) {
      let userId = e.target.getAttribute("value");
      axios
        .post("/api/users/admin/delete", { userId: userId })
        .then((res) => {
          this.getUsers(this.state.page);
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  }

  render() {
    let { errors } = this.state;
    let userData;
    if (userData) {
      return <Spinner />;
    }
    userData = this.state.users.map((user, i) => {
      let bg2 = i % 2 === 0 ? "#ececec" : "";
			let avatar = user.avatar;
			let size;
			if(user.avatar.indexOf('gravatar') < 0){
				size="sm";
			}
			else{
				size="orig"
			}
			
      return (
        <tr
          className={`p-1 m-1`}
          key={user._id}
          style={{ borderBottom: "1px solid #000", background: bg2 }}
        >
          <td className="pt-1 pb-1" align="center">
            {user.avatar ? (
              <Link to={`/admin/users/${user._id}`}>
                <ImageLoader
                  alt="avatar"
                  src={avatar}
									height="65px"
									size={size}
                ></ImageLoader>
              </Link>
            ) : null}
          </td>
          <td>
            <Link to={`/admin/users/${user._id}`}>{user.name}</Link>
          </td>
          <td>
            <i
              onClick={this.deleteUser}
              value={user._id}
              className="delete-user text-danger fa fa-window-close mr-2"
            ></i>
            {user.email}
          </td>
          <td align="center">
            {user.confirmed ? (
              <i
                name="confirmed"
                onClick={this.onClick}
                className="fa fa-check-square"
                ischecked="true"
                value={user._id}
              ></i>
            ) : (
              <i
                name="confirmed"
                onClick={this.onClick}
                className="fa fa-square"
                ischecked="false"
                value={user._id}
              ></i>
            )}
          </td>
          <td align="center">
            {user.approved ? (
              <i
                name="approved"
                onClick={this.onClick}
                className="fa fa-check-square"
                ischecked="true"
                value={user._id}
              ></i>
            ) : (
              <i
                name="approved"
                onClick={this.onClick}
                className="fa fa-square"
                ischecked="false"
                value={user._id}
              ></i>
            )}
          </td>
        </tr>
      );
    });

    return (
      <div className="manage-users mt-1">
        <h3 className="text-center">LukeDev Users</h3>
        <div>
          <div
            className="user-search mt-2 col"
            style={{ minWidth: "400px", maxWidth: "600px", margin: "0 auto" }}
          >
            <TextFieldGroup
              placeholder="Search"
              name="search"
              value={this.state.search}
              onChange={this.onChange}
              error={errors.search}
            />
          </div>
        </div>
        <div className="h4">
          Showing {(this.state.page - 1) * this.state.limit + 1} -
          {this.state.page * this.state.limit > this.state.count
            ? this.state.count
            : this.state.page * this.state.limit}
          &nbsp;of&nbsp;
          {this.state.count}
        </div>
        {this.state.page > 1 ? (
          <button
            className="d-inline mr-2 btn btn-secondary"
            onClick={this.changePage}
            value="down"
          >
            Prev Page
          </button>
        ) : (
          <button
            className="d-inline mr-2 btn btn-secondary"
            value="down"
            disabled
          >
            Prev Page
          </button>
        )}
        {this.state.page < this.state.count / this.state.limit ? (
          <button
            className="d-inline btn btn-info"
            onClick={this.changePage}
            value="up"
          >
            Next Page
          </button>
        ) : (
          <button className="d-inline btn btn-info" value="up" disabled>
            Next Page
          </button>
        )}
        <table className="w-100">
          <thead>
            <tr>
              <td className="h5 p-1" align="center">
                <u>Avatar</u>
              </td>
              <td className="h5">
                <u>Name</u>
              </td>
              <td className="h5">
                <u>Email</u>
              </td>
              <td className="h5" align="center">
                <u>Confirmed</u>
              </td>
              <td className="h5" align="center">
                <u>Approved</u>
              </td>
            </tr>
          </thead>
          <tbody>{userData.length > 0 ? userData : null}</tbody>
        </table>
      </div>
    );
  }
}

export default ManageUsers;
