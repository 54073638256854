import React, { Component } from "react";
import { Link } from "react-router-dom";
import ImageLoader from "../ImageLoader";

class FullWidthContentCard extends Component {
  render() {
    let content = this.props.content;
    return (
      <div className="card p-2">
        <div className="row h-100">
          <div className="col-12 col-lg-4 d-flex text-center pr-0">
			{content.img ? (
              <Link
                to={content.url}
                className="justify-content-center align-self-center mb-2 mt-2"
				style={{ margin: "0 auto" }}
              >
							<ImageLoader
								src={content.img.src}
								size={content.img.size ? content.img.size : null}
								alt={content.img.alt ? content.img.alt : null}
							/>
              </Link>
            ) : null}
          </div>
          <div className="col-12 col-lg-8 d-flex pl-0">
            <div className="col">
              <div className="mb-1 text-center">
                <Link
                  to={content.url}
                  className="text-center h4 justify-content-center align-self-center"
                >
                  {content.title}
                </Link>
              </div>
              <p>{content.text}</p>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}

export default FullWidthContentCard;
