import React, { Component } from "react"; 
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { getAllGames } from '../../../../actions/farkleActions';
import { getUsers } from '../../../../actions/authActions';
import axios from "axios";


class AddFarkleFriends extends Component {
	constructor(props){
		super(props);
		this.state = {
			games: {},
			players: {}
		}
	}

	componentDidMount(){
		//this.props.getUsers();
		axios
		.get('/api/users')
		.then( res => {
			this.setState({players: res.data});
		})
		.catch(err => {
			console.warn(err);
		})
	}

	

	render() {
		let nonfriendUsers;

		return (
		 <div className="container">
			 Add Friends
			 {nonfriendUsers}
		 </div>
		);
	}
}

AddFarkleFriends.propTypes = {
	games: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	getAllGames: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	games: state.games,
	auth: state.auth,
});

export default connect(mapStateToProps, {getAllGames, getUsers})(AddFarkleFriends);