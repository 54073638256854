import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class ProjectsLanding extends Component {
  render() {
    return (
      <div className="landing">
        <div className="dark-overlay text-light">
          <div className="container mb-1">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="h2 mt-4">LukeDev Projects</div>
              </div>
            </div>
          </div>
          <div className="bg-light" style={{ width: "100%" }}>
            <div className="container pb-4">
              <div className="row pl-3 pr-3 mt-3 text-dark pt-2">
                <div className="col col-md12 card p-3 mr-2">
                  <Link
                    to="/petal"
                    className="underline h3 text-info text-center"
                  >
                    Petal
                  </Link>
                  <div className="h5 text-center">
                    PETAL stands for "Prototyping, Experimentation, Testing, and
                    Analytics by LukeDev."
                  </div>
                  <p className="mt-3">
                    As it stands now, trying to get products, features, updates,
                    AB tests, or analytics deployed takes a lot of time, a lot
                    of effort, and a lot of resources. What we are trying to do
                    is put together an entire ecosystem that will allow you to
                    use a simple web interface and, if you want, utilize our
                    Chrome plugin, to run through the processes quickly.
                  </p>

                  <p>
                    As it stands right now, this project is in its infancy, so
                    not much to show as of yet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProjectsLanding.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ProjectsLanding);
