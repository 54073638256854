import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { getSectionData } from "../../actions/sectionActions";
import RedditModule from "../common/modules/Reddit";
import { Link } from "react-router-dom";
import WiredNews from "../common/modules/WiredNews";
import LinuxMagazineNews from "../common/modules/LinuxMagazineNews";
class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: {},
      currentInfo: "",
    };
  }

  render() {
    // FINAL RENDER
    return (
      <div className="landing category-landing">
        <div className="dark-overlay text-light pb-2">
          {/*--------------Title Bar ----------------*/}
          <div className="container mb-1">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="h2 mt-4">
                  Welcome to the LukeDev.net Portal!
                </div>
              </div>
            </div>
          </div>

          {/*----------------Header --------------------*/}
          <div className="bg-light" style={{ width: "100%" }}>
            <div className="container" style={{  fontSize: "1.2rem" }}>
              <div className="row pl-3 pr-3 mt-3 text-dark pt-2 pb-2">
                <div className="col-md-12 h2 text-center">What is LukeDev?</div>
                <div className="col-md-12 h4 text-center"></div>
                <p>
                  This is the main portal. From here, you will find links to the
                  projects, photography, tools, blogs, and more that I work on.
                  Mostly it is just me, but sometime I have collaborations with
                  others for parts.
                </p>

                <p>
                  LukeDev is my place to work on a lot of projects. Some are for
                  experimentation, some are just tool that make my life easier,
                  some of them have the potential to become new products
                  (possibly to sell one day).
                </p>

                <p>
                  The linked sites run on a variety of platforms. This main site
                  is Node/Express/React/Redux/Mongo with just some Bootstrap to
                  make styling easier.
								</p>
								<p>
									Oh yeah...  You might find a bunch of pictures of my dog throughout.  But he can emote all the feelings we need, so I figure that is appropriate.
								</p>
              </div>
            </div>
          </div>
          {/*--------------END HEADER-------------------*/}

          <div className="container mt-2">
            <div className="row bg-light">
              <div className="col col-12 col-lg-8 mt-3">
                <div className="row  pl-1 pr-1 mt- text-dark">
									<div className="col col-12 col-lg-12 col-xl-6 mb-3 ">
										<div className="card p-2 h-100 bg-white">
											<Link
												to="/category/projects"
												className="underline h3 text-info text-center"
											>
												Projects
											</Link>
											<div className="h5 text-center">
												A List Of Various Stuff I Work On And With
											</div>
											<p className="mt-3">
												This section will lead you to a variety of projects I
												have worked on. Some are unfinished, some are ugly, but
												all are things that I find interesting as tools, thought
												experiments, or items to hone certain skillsets.
											</p>
										</div>
									</div>
									<div className="col col-12 col-lg-12 col-xl-6 mb-3 ">
                    <div className="card p-2 h-100 bg-white">
                      <Link
                        to="/petal"
                        className="underline h3 text-info text-center"
                      >
                        Petal
                      </Link>
                      <div className="h5 text-center">
                        PETAL stands for "Prototyping, Experimentation, Testing,
                        and Analytics by LukeDev."
                      </div>
                      <p className="mt-3">
                        As it stands now, trying to get products, features,
                        updates, AB tests, or analytics deployed takes a lot of
                        time, a lot of effort, and a lot of resources. What we
                        are trying to do is put together an entire ecosystem
                        that will allow you to use a simple web interface and,
                        if you want, utilize our Chrome plugin, to run through
                        the processes quickly.
                      </p>
                    </div>
                  </div>

                  <div className="col col-12 col-lg-12 col-xl-6 mb-3 ">
                    <div className="card p-2 h-100 bg-white">
                      <a
                        href="https://blog.lukedev.net/"
                        rel="noopener noreferrer"
                        className="underline h3 text-info text-center"
                        target="_blank"
                      >
                        LD Tech Blog
                      </a>
                      <div className="h5 text-center">
                        A Blog For My Thoughts
                      </div>
                      <p className="mt-3">
                        This is a blog of my thoughts. This will cover projects
                        I am considering, progress on current projects, thoughts
                        on various technologies, and probably some ramblings
                        about how the Kraken is sabotaging my code.
                      </p>
                    </div>
                  </div>

                  <div className="col col-12 col-lg-12 col-xl-6 mb-3 ">
                    <div className="card p-2 h-100 bg-white">
                      <a
                        href="https://photos.lukedev.net"
                        rel="noopener noreferrer"
                        className="underline h3 text-info text-center"
                        target="_blank"
                      >
                        Photography
                      </a>
                      <div className="h5 text-center">
                        I like to think of myself as an amateur photographer
                      </div>
                      <p className="mt-3">
                        Everyone likes to think they are an amateur
                        photographer. Well, that goes for me too. I use this
                        space to organize some of my pictures and try to keep a
                        hobby going to relax from coding. It also gives me a
                        project to work on that 1) serves a useful purpose for
                        me, personally, and 2) it is fun to try to capture the
                        world inside a camera.
                      </p>
                      <p>
                        Sidenote: Yes, I know there are going to be a LOT of my
                        dog... But he is so photogenic and surprising, that it
                        was bound to happen
                      </p>
                    </div>
                  </div>
								</div>
								<WiredNews />
								<LinuxMagazineNews />
              </div>
              <div id="right-rail" className="col col-12 col-lg-4 mt-3 bg-light text-dark pt-2">
                <iframe
                  scrolling="no"
                  title="news-widget"
                  id="widget"
                  className="card mb-2"
                  src="/public/widget.html?gfd=77"
                  style={{ width: "100%", scroll: "none" }}
                ></iframe>
                <h3 className="text-center">Reddit Streams</h3>
                <RedditModule key="4" stream="linux" limit="10"></RedditModule>
							</div>
							
						</div>
						
          </div>
          {/*----------- Cards --------------------  */}
          <div className="container"></div>
          {/*-------------END CARDS -------------- */}
        </div>
      </div>
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  getSectionData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getSectionData })(Landing);
