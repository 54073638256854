import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Spinner from "../../common/Spinner";
import ImageLoader from "../../common/ImageLoader";

class AssetsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      data: [],
    };

    this.deleteData = this.deleteData.bind(this);
  }

  componentDidMount() {}

  deleteData(e) {
    if (!window.confirm("Are you sure?  This cannot be undone!")) {
      return;
    }

    let val = e.target.getAttribute("value");
    let arr;
    if (this.state.data.length === 0) {
      this.setState({ data: this.props.data });
      arr = this.props.data;
    } else {
      arr = this.state.data;
    }

    axios
      .post("/api/content/delete", { id: val })
      .then((res) => {
        let data = arr.filter((dt) => {
          return dt.id !== val * 1;
        });
        this.setState({
          data: data,
        });
      })
      .catch((err) => console.warn(err));
  }

  render() {
    let data;
    if (this.state.data.length > 0) {
      data = this.state.data;
    } else {
      data = this.props.data;
    }
    if (data.length === 0) {
      return <Spinner />;
    }

    let assetDisplay = data.map((asset, i) => {
      return (
        <div key={i} className="col-12 col-md-12 col-lg-4 col-xl-3 p-1">
          <Link
            to={`/admin/edit/asset/${asset.id}`}
            className=" bg-white p-2 card asset-card h-100"
          >
            <div>
              <ImageLoader
                alt={asset.alt}
                src={
                  asset.file_path
                    ? asset.file_path
                    : "/images/icons/noasset.jpg"
                }
              />
            </div>
            <div className="h5 text-center mt-2">{asset.name}</div>
          </Link>
        </div>
      );
    });

    return (
      <div className="ml-2 text-center mt-3">
        <div className="row text-center ">
          <div className="col">
            {data.length > 0 ? null : (
              <div className="text-danger h4 text-center mt-4 p-3">
                No contents match your search
              </div>
            )}
          </div>
        </div>
        <div className="row w-100">{assetDisplay}</div>
      </div>
    );
  }
}

export default AssetsTable;
