import React, { Component } from "react";
import isEmpty from "../../../validation/is-empty";
import Spinner from "../../common/Spinner";

class ProfileHeader extends Component {
  render() {
    const { profile } = this.props;
    if (profile === null || profile.loading) {
      return <Spinner></Spinner>;
    }

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card card-body bg-info text-white mb-3">
            <div className="row">
              <div
                className="col-12 col-sm-12 col-md-12 col-lg-5 ml-1"
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px",
                }}
              >
                <img
                  className="rounded-circle"
                  src={
                    profile.user.avatar.split(".").slice(0, -1).join(".") +
                    "_sm.jpg"
                  }
                  alt=""
                  style={{
                    border: "4px solid #ccc",
                    maxHeight: "200px",
                    width: "auto",
                    margin: "0 auto",
                  }}
                />
							</div>
							
              <div
                className="col-12 col-md-12 col-lg-6 text-center pr-lg-0 pr-xl-3"
                style={{ margin: "0 auto", marginLeft: "0px" }}
              >
                <h1 className="display-5 text-center">{profile.user.name}</h1>
                <p className="lead text-center">
                  {profile.status}{" "}
                  {profile.company ? "at " + profile.company : null}
                </p>
                <p>{profile.location}</p>
                <div className="text-center container" style={{ width: "80%" }}>
                  {isEmpty(profile.website) ? null : (
                    <div>
                      <a className="text-white p-2" href={profile.website}>
                        <i className="fa fa-globe mr-3 fa-1x"></i>Website
                      </a>
                    </div>
                  )}

                  {isEmpty(profile.social && profile.social.twitter) ? null : (
                    <div>
                      <a
                        className="text-white p-2"
                        href={profile.social.twitter}
                      >
                        <i className="fa fa-twitter mr-3 fa-1x"></i>Twitter Home
                      </a>
                    </div>
                  )}

                  {isEmpty(profile.social && profile.social.facebook) ? null : (
                    <div>
                      <a
                        className="text-white p-2"
                        href={profile.social.facebook}
                      >
                        <i className="fa fa-facebook mr-3 fa-1x"></i>Facebook
                        Profile
                      </a>
                    </div>
                  )}
                  {isEmpty(profile.social && profile.social.linkedin) ? null : (
                    <div>
                      <a
                        className="text-white p-2"
                        href={profile.social.linkedin}
                      >
                        <i className="fa fa-linkedin mr-3 fa-1x"></i>LinkedIn
                        Profile
                      </a>
                    </div>
                  )}
                  {isEmpty(
                    profile.social && profile.social.instagram
                  ) ? null : (
                    <div>
                      <a
                        className="text-white p-2"
                        href={profile.social.instagram}
                      >
                        <i className="fa fa-instagram mr-3 fa-1x"></i>Instagram
                        Home
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileHeader;
