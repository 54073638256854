import React, { Component } from "react"; 
import axios from "axios";
import TextFieldGroup from "../../common/TextFieldGroup";
import {Link} from 'react-router-dom'
import Spinner from "../../common/Spinner";
class NewSection extends Component {
	constructor(props){
		super(props);
		this.state = {
			errors: {},
			data: {},
			name: '',
			site_name: '',
			url: '',
			page_type: '',
			parent: '',
			content_id: '',
			saved: null,
			dataType: '', 
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	getData(){
		let sid = this.props.sid;
		axios.get(`/api/sections/content/${sid}`)
			.then(res => {
				this.setState({data: res.data});
			})
	}

	componentDidMount(){
		this.getData();
	}

	onClick(e){
		if(e.target.value === "cancel")
		{
			document.location.href = `/admin/manage-data/${this.props.dataType}`;
		}
		if(e.target.value === "goBack")
		{
			document.location.href = `/admin/manage-data/${this.props.dataType}`;
		}
	}

	onChange(e){
		this.setState({
			data: { 
				...this.state.data,
				[e.target.name]: e.target.value
			},
			saved: false,
			dataType: this.props.dataType
		});
		this.setState({
			saved: false
		})
	}

	onSubmit(e){
		e.preventDefault();
		let errors={};
		if(this.state.name.length <= 3 )
		{
			errors.name = "The title must be over 5 characters long"
		}
		if(this.state.site_name.length < 5 )
		{
			errors.site_name = "The site name must be over 5 characters long"
		}
		if(this.state.url.length < 5 )
		{
			errors.url = "The url must be over 5 characters long"
		}
		if(this.state.page_type.length < 5 )
		{
			errors.page_type = "The page type must be over 5 characters long"
		}
		if(this.state.parent.length < 3 )
		{
			errors.parent = "The parent field must be over 3 characters long"
		}
		if(this.state.content_id.length === 0 )
		{
			errors.content_id = "The content ID must not be blank"
		}
		
		if(errors.length >0)
		{
			this.setState({errors: errors});	
			return;
		}
		
		let newSection = {
			id: this.props.sid,
			name: this.state.data.name.trim(),
			site_name: this.state.data.site_name.trim(),
			url: this.state.data.url,
			page_type: this.state.data.page_type,
			parent: this.state.data.parent,
			content_id: this.state.data.content_id,
		}

		axios.post("/api/sections/update", newSection)
			.then(res => {
				this.setState({saved: true});
			})
			.catch(err => {
				this.setState({errors: err.response.data})
			})
		
	}
	
	render() {
		let {errors} = this.state;
		let data= this.state.data;

		if(!this.state.data.name){
			return <Spinner />
		}

		let fields = [
			"name", "site_name", "url", "page_type", "parent", "content_id"
		];

		let fieldInputs = fields.map((field, i) => {
			return (
				<div key={i}>
					<TextFieldGroup
						name={field}
						type="text"
						value={data && data[field] ? this.state.data[field].toString() : ''}
						onChange={this.onChange}
						error={errors[field]}
					/>
				</div>
			)
		});
		
		return (
			<div>
				<h3 className="text-center mt-2">
					<div>Edit Section: {data && this.state.data.name}</div>
				</h3>
				
				{this.state.saved ? 
					<h4 className="text-success text-center mt-1">New Content Saved</h4> : 
					( (this.state.saved === null) ? <h4 className="text-center text-success mt-1">Content Unchanged</h4> :
					<h4 className="text-warning text-center mt-1">Content Not Saved</h4>)
				} 
				<div className="row">
					<div className="container" style={{ maxWidth: "800px" }}>
						<form onSubmit={this.onSubmit}>
						{fieldInputs}
						<div className="text-center mt-3">
							<input type="submit" className="btn btn-success pl-3 pr-3" value="Save"></input>
							<Link className="btn text-white btn-danger ml-2" to="/admin/manage-data/sections" value="cancel">Cancel</Link>
						</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

export default NewSection;