import React, { Component } from "react"; 
import axios from "axios";
import {Link} from "react-router-dom";
import TextFieldGroup from "../../common/TextFieldGroup";
import ImageLoader from "../../common/ImageLoader";

class ManageContent extends Component {
	constructor(props){
		super(props);
		this.state = {
			errors: {},
			search: '',
			contents: [],
			limit: 5,
		}
		this.onChange = this.onChange.bind(this);
		this.findContentsByString = this.findContentsByString.bind(this);
	}
	
	getContents(){
		axios.get('/api/content')
			.then(res => {
				this.setState({contents: res.data})
			})
	}

	componentDidMount(){
		this.getContents();
	}

	findContentsByString(str){
		
		axios.post('/api/content/search', {search: str, limit: this.state.limit})
			.then(res => {
				this.setState({contents: res.data})
			})
			.catch(err => {
				console.warn(err);
				this.setState({errors: err})
			})
	}

	onChange(e){
		if(e.target.name === "search")
		{
			this.setState({[e.target.name]: e.target.value});
			this.findContentsByString(e.target.value);
			
		}
	}

	render() {
		let {errors} = this.state;
		let contentData;
		contentData = this.state.contents.map( (content, i) => {
			let bg2 = ( (i%2 === 0) ? "#ececec" : '' )
			return (
				<tr key={i} className={`p-1 m-1`}  style={{ borderBottom: "1px solid #000", background: bg2 }}>
					<td className="text-center">
						<Link to={`edit-content/${content.id}`}>
							<ImageLoader alt="featured" src={content.featured_image ? content.featured_image : "/images/icons/nocontent.jpg"} className="p-1" style={{ height: "85px", width: "auto" }} />
						</Link>	
					</td>
					<td className="text-capitalize">
						<Link to={`edit-content/${content.id}`} >{content.title.replace(/_/g, ' ')}</Link>
					</td>
					<td>{content.slug}</td>
					<td>{content.tags}</td>
				</tr>
			)
		})

		return (
			<div>
				<h3 className="text-center">
					<button className="btn btn-success float-left" onClick={this.onClick}>New {this.state.dataType}</button>
					LukeDev contents
				</h3>
				<div className="user-search mt-2 col" style={{ minWidth: "400px", maxWidth: "600px", margin: "0 auto"  }}>
					<TextFieldGroup
						placeholder="Search"
						name="search"
						value={this.state.search}
						onChange={this.onChange}
						error={errors.search}
						style={{ width: "40px" }}
					/>
					</div>
				<div className="row text-center">
					<div className="col">
						{contentData.length > 0 ? null : <div className="text-danger h4 text-center mt-4 p-3">No contents match your search</div> }
					</div>
				</div>

				<table className="w-100">
					<thead>
						<tr>
							<td className="h5 p-1 text-center"><u>Featured Image</u></td>
							<td className="h5"><u>Title</u></td>
							<td className="h5"><u>Slug</u></td>
							<td className="h5"><u>Tags</u></td>
						</tr>
					</thead>
					<tbody>
						{contentData.length > 0 ? contentData : null}
					</tbody>
				</table>	
			</div>
		);
	}
}

export default ManageContent;