import React, { Component } from "react";
import axios from "axios";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword: "",
      newpassword: "",
      cpassword: "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    let err = {};
    if (this.state.newpassword !== this.state.cpassword) {
      err.nomatch = "Your new password and confirm password must match";
    }
    if (this.state.newpassword.length < 7) {
      err.newpassword = "Your new password must be more than 7 characters long";
    }

    let newpass = {
      password: this.state.oldpassword,
      newpassword: this.state.newpassword,
      cpassword: this.state.cpassword,
    };
    axios
      .post("/api/users/changePassword", newpass)
      .then((res) => {
        this.props.history.push("/dashboard");
      })
      .catch((err) => {
        this.setState({ errors: err.response.data });
        console.warn(err);
      });
  }

  render() {
    let { errors } = this.state;
    return (
      <div className="container">
        <form
          onSubmit={this.onSubmit}
          style={{ maxWidth: "600px", margin: "0 auto" }}
          className="text-center"
        >
          <h3 className="mb-4">Change Your Password</h3>
          <div className="password-fields mt-2">
            <label className="mr-3" style={{ minWidth: "150px" }}>
              Old Password
            </label>
            <input
              type="password"
              name="oldpassword"
              id="passwordInput"
              onChange={this.onChange}
            />
          </div>
          <div className="text-danger">
            {errors.password ? errors.password : null}
            {errors.invalidPassword ? errors.invalidPassword : null}
          </div>
          <div className="password-fields">
            <label className="mr-3" style={{ minWidth: "150px" }}>
              New Password
            </label>
            <input
              type="password"
              name="newpassword"
              id="passwordInput"
              onChange={this.onChange}
            />
          </div>
          <div className="text-danger">
            {errors.newpassword ? errors.newpassword : null}
          </div>
          <div className="password-fields">
            <label className="mr-3" style={{ minWidth: "150px" }}>
              Confirm Password
            </label>
            <input
              type="password"
              name="cpassword"
              id="passwordInput"
              onChange={this.onChange}
            />
          </div>
          <div className="text-danger">
            {errors.cpassword ? errors.cpassword : null}
          </div>
          <div className="text-danger">
            {errors.nomatch ? errors.nomatch : null}
          </div>
          <input
            type="submit"
            value="upload"
            className="btn btn-primary btn-block mt-4"
          ></input>
        </form>
      </div>
    );
  }
}

export default ChangePassword;
