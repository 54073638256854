import React, { Component } from "react";
import { parseContent } from "../../utils/contentParser";
import axios from "axios";

class LeftColContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
    };
  }

  componentDidMount() {
    axios
      .get("/api/content/landing/leftColContent/" + this.props.category)
      .then((res) => {
        this.setState({ content: res });
      })
      .catch((err) => console.warn(err));
  }

  render() {
    let text_paragraphs;

    let currentInfo = this.props.currentInfo;

    if (typeof currentInfo !== "undefined") {
      if (currentInfo.text !== null && currentInfo.text.length > 6) {
        let currentItems = JSON.parse(currentInfo.text);
        text_paragraphs = currentItems.map((item, index) => {
          return parseContent(item, index);
        });
      }
    }

    return (
      <div key={"random"}>
        {typeof currentInfo !== "undefined" ? text_paragraphs : null}
      </div>
    );
  }
}

export default LeftColContent;
