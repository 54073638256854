import React, { Component } from "react"; 
import axios from "axios";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaFieldGroup from "../../common/TextAreaFieldGroup";

class NewContent extends Component {
	constructor(props){
		super(props);
		this.state = {
			errors: {},
			data: {},
			title: '',
			slug: '',
			featured_image: '',
			description: '', 
			text: '',
			tags: '',
			content_type: '',
			canonical_name: '',
			saved: false,
			datatype: '',
		}
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.checkValidJSON = this.checkValidJSON.bind(this);
	}

	componentDidMount(){
		this.setState({
			description: JSON.stringify(JSON.parse(`[
				{
					"p": ""
				}
			]`),null,2),
			text: JSON.stringify(JSON.parse(`[
				{
					"p": ""
				}
			]`),null,2),
		})
	}

	onClick(e){
		if(e.target.value === "cancel")
		{
			document.location.href = `/admin/manage-data/${this.props.dataType}`;
		}
		if(e.target.value === "goBack")
		{
			document.location.href = `/admin/manage-data/${this.props.dataType}`;
		}
	}

	onChange(e){
		this.setState({
			 [e.target.name]: e.target.value,
			 saved: false,
			 dataType: this.props.dataType
		});
	}

	onSubmit(e){
		e.preventDefault();
		let errors={};
		if(!this.checkValidJSON(this.state.description))
		{
			errors.description = "Invalid description JSON";
		}
		if(!this.checkValidJSON(this.state.text))
		{
			errors.text = "Invalid text JSON";
		}
		if(this.state.title.length <= 3 )
		{
			errors.title = "The title must be over 5 characters long"
		}
		if(this.state.slug.length < 15 )
		{
			errors.slug = "The slug must be over 15 characters long"
		}
		
		if(errors.length > 0)
		{
			this.setState({errors: errors});	
			return;
		}
		
		let newContent = {
			description: this.state.description.trim(),
			text: this.state.text.trim(),
			featured_image: this.state.featured_image,
			title: this.state.title,
			slug: this.state.slug,
			tags: this.state.tags,
			content_type: this.state.content_type,
			canonical_name: this.state.canonical_name
		}

		axios.post("/api/content/insert", newContent)
			.then(res => {
				this.setState({saved: true});
			})
			.catch(err => {
				this.setState({errors: err.response.data})
			})
		
	}

	checkValidJSON(str){
		if ( (/^[\],:{}\s]*$/).test(str.replace(/\\["\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) 
		{
			return true
		}
		else{
			return false
		}
	}
	
	render() {
		let {errors} = this.state;

		return (
			<div id="edit-content-form">
				<h3 className="text-center text-capitalize mt-2 ">
					New Content: {this.state.title}
					<button className="btn btn-secondary float-left text-capitalize" onClick={this.onClick} value="goBack">Back to {this.props.dataType}</button>
				</h3>
				{this.state.saved ? <h4 className="text-success text-center mt-1">New Content Saved</h4> : null} 
				<div className="row">
					<div className="container">
						<form onSubmit={this.onSubmit}>
							<div className="row">
								<div className="col col-12 col-lg-8">
									<TextAreaFieldGroup
										placeholder="Content (in json format)"
										name="description"
										value={this.state.description}
										onChange={this.onChange}
										onClick={this.checkValidJSON}
										error={errors.description}
										info="Tell us a little about yourself"
									/>
									<TextAreaFieldGroup
										placeholder="Content (in json format)"
										name="text"
										value={this.state.text !== "null" ? this.state.text : ''}
										onChange={this.onChange}
										error={errors.text}
										info="Tell us a little about yourself"
									/>
									
								</div>
								<div className="col col-12 col-lg-4">
									<TextFieldGroup
										name="featured_image"
										value={this.state.featured_image ? this.state.featured_image : "/images/icons/nocontent.jpg"}
										onChange={this.onChange}
										error={errors.featured_image}
									/>
									<img className="mb-2" alt="no content" src={this.state.featured_image ? this.state.featured_image : "/images/icons/nocontent.jpg"} ></img>
									<TextFieldGroup
										name="title"
										value={this.state.title}
										onChange={this.onChange}
										error={errors.title}
									/>
									<TextFieldGroup
										name="slug"
										value={this.state.slug}
										onChange={this.onChange}
										error={errors.slug}
									/>
									<TextFieldGroup
										name="tags"
										value={this.state.tags ? this.state.tags : ''}
										onChange={this.onChange}
										error={errors.tags}
									/>
									<TextFieldGroup
										name="content_type"
										value={this.state.content_type ? this.state.content_type : ''}
										onChange={this.onChange}
										error={errors.content_type}
									/>
									<TextFieldGroup
										name="canonical_name"
										value={this.state.canonical_name ? this.state.canonical_name : ''}
										onChange={this.onChange}
										error={errors.canonical_name}
									/>
									<div className="text-center mt-3">
										<input type="submit" className="btn btn-success pl-3 pr-3" value="Save"></input>
										<button className="btn btn-danger ml-2" onClick={this.onClick} value="cancel">Cancel</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

export default NewContent;