
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from "react-redux";
import store from './store';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import './App.css';

import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import AllRoutes from './routes/all';


if(localStorage.jwtToken){
	//set auth token header auth
	setAuthToken(localStorage.jwtToken);
	// decode token and get user info and expiration
	const decoded = jwt_decode(localStorage.jwtToken);
	// set user and isAuthenticated
	store.dispatch(setCurrentUser(decoded));

	//check for expired token
	const currentTime = Date.now()/1000;
	if(decoded.exp < currentTime)
	{
		//logout user
		store.dispatch(logoutUser());
		window.location.href = "/login";
	}

}

function App() {
  return (
	<Provider store={store}>
		<Router>
			<div className="App">
				<Navbar/>
				
				<AllRoutes></AllRoutes>
					
				<Footer />
			</div>
		</Router>
	</Provider>
  );
}

export default App;
