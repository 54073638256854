import React, { Component } from "react"; 
import axios from "axios";
import TextFieldGroup from "../../common/TextFieldGroup";
import TextAreaFieldGroup from "../../common/TextAreaFieldGroup";
import { Link } from "react-router-dom";

class EditContent extends Component {
	constructor(props){
		super(props);
		this.state = {
			errors: {},
			data: {},
			title: '',
			slug: '',
			featured_image: '',
			description: '', 
			text: '',
			tags: '',
			content_type: '',
			canonical_name: '',
			saved: null,
		}
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.checkValidJSON = this.checkValidJSON.bind(this);
	}
	
	getData(){
		let sid = this.props.sid;
		axios.get(`/api/content/content/${sid}`)
			.then(res => {
				this.setState({
					title: res.data.title,
					slug: res.data.slug,
					featured_image: res.data.featured_image,
					description: JSON.stringify(JSON.parse(res.data.description),null,2), 
					text: JSON.stringify(JSON.parse(res.data.text),null,2),
					tags: res.data.tags,
					content_type: res.data.content_type,
					canonical_name: res.data.canonical_name
				});
			})
	}

	componentDidMount(){
		this.getData();
	}

	onChange(e){
		this.setState({ 
			[e.target.name]: e.target.value,
			saved: false,
		});
	}

	onSubmit(e){
		e.preventDefault();
		let errors={};
		
		try {
				JSON.parse(this.state.description);
		} catch (e) {
			errors.description = "Invalid description JSON";
		}
		
		try {
			JSON.parse(this.state.text);
		} catch (e) {
			errors.text = "Invalid text JSON";
		}
		
		
		if(this.state.title.length <= 3 )
		{
			errors.title = "The title must be over 5 characters long"
		}
		if(this.state.slug.length < 15 )
		{
			errors.slug = "The slug must be over 15 characters long"
		}
		
		this.setState({errors: errors});
		if(Object.keys(errors).length)
		{
				
			return;
		}
		
		let updatedPost = {
			id: this.props.sid,
			description: this.state.description.trim(),
			text: this.state.text.trim(),
			featured_image: this.state.featured_image,
			title: this.state.title,
			slug: this.state.slug,
			tags: this.state.tags,
			content_type: this.state.content_type,
			canonical_name: this.state.canonical_name,
		}
		console.log(updatedPost);
		axios.post("/api/content/update/", updatedPost)
			.then(res => {
				this.setState({saved: true});
			})
			.catch(err => {
				this.setState({errors: err.response.data})
			})
		
	}

	

	checkValidJSON(str){
		if ( (/^[\],:{}\s]*$/).test(str.replace(/\\["\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) 
		{
			return true
		}
		else{
			return false
		}
	}
	
	render() {
		let {errors} = this.state;
		
		return (
			<div id="edit-content-form">
				<h3 className="text-center text-capitalize mt-2 ">Edit Content: {this.state.title}</h3>
				{this.state.saved ? 
					<h4 className="text-success text-center mt-1">New Content Saved</h4> : 
					( (this.state.saved === null) ? <h4 className="text-center text-success mt-1">Content Unchanged</h4> :
					<h4 className="text-warning text-center mt-1">Content Not Saved</h4>)
				} 
				<div className="row">
					<div className="container">
						<form onSubmit={this.onSubmit}>
							<div className="row">
								<div className="col col-12 col-lg-8">
									<TextAreaFieldGroup
										placeholder="Content (in json format)"
										name="description"
										value={this.state.description}
										onChange={this.onChange}
										onClick={this.checkValidJSON}
										error={errors.description}
										info="Tell us a little about yourself"
									/>
									<TextAreaFieldGroup
										placeholder="Content (in json format)"
										name="text"
										value={this.state.text !== "null" ? this.state.text : ''}
										onChange={this.onChange}
										error={errors.text}
										info="Tell us a little about yourself"
									/>
									
								</div>
								<div className="col col-12 col-lg-4">
									<TextFieldGroup
										name="featured_image"
										value={this.state.featured_image ? this.state.featured_image : "/images/icons/nocontent.jpg"}
										onChange={this.onChange}
										error={errors.featured_image}
									/>
									<img className="mb-2" alt="no content" src={this.state.featured_image ? this.state.featured_image : "/images/icons/nocontent.jpg"} ></img>
									<TextFieldGroup
										name="title"
										value={this.state.title}
										onChange={this.onChange}
										error={errors.title}
									/>
									<TextFieldGroup
										name="slug"
										value={this.state.slug}
										onChange={this.onChange}
										error={errors.slug}
									/>
									<TextFieldGroup
										name="tags"
										value={this.state.tags ? this.state.tags : ''}
										onChange={this.onChange}
										error={errors.tags}
									/>
									<TextFieldGroup
										name="content_type"
										value={this.state.content_type ? this.state.content_type : ''}
										onChange={this.onChange}
										error={errors.content_type}
									/>
									<TextFieldGroup
										name="canonical_name"
										value={this.state.canonical_name ? this.state.canonical_name : ''}
										onChange={this.onChange}
										error={errors.canonical_name}
									/>
									<div className="text-center mt-3">
										<input type="submit" className="btn btn-info"></input>
										<Link to="/admin/manage-data/content" className="btn btn-danger text-white ml-2 pl-4 pr-4">Back</Link>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

export default EditContent;