import React, { Component } from "react";

class TitleBar extends Component {
  render() {
    let currentInfo = this.props.currentInfo;

    return (
      <div className="container mb-1">
        <div className="row">
          <div className="col-md-12 text-center">
            <div
              onClick={this.props.history.goBack}
              className="btn btn-secondary btn-md mt-3 float-left"
              style={{ position: "relative", top: "3px" }}
            >
              &#x2B05; Back
            </div>
            <div className="h2 mt-3 text-capitalize">
              {typeof currentInfo !== "undefined"
                ? currentInfo.title.replace(/_/g, " ")
                : null}{" "}
              Home
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TitleBar;
