import React, { Component } from "react";
import { parseContent } from "../../utils/contentParser";
import ImageLoader from "../common/ImageLoader";

class CategoryLandingHeader extends Component {
  render() {
    const currentInfoProp = this.props.currentInfo;
    let currentInfo;

    if (typeof currentInfoProp !== "undefined") {
      currentInfo = currentInfoProp;
    }

    let paragraphs;
    if (typeof currentInfo !== "undefined") {
      let contentItems = JSON.parse(currentInfo.description);
      paragraphs = contentItems.map((item, index) => {
        return parseContent(item, index);
      });
    }

    return (
      <div className="category-header bg-light pb-3" style={{ width: "100%" }}>
        <div className="container">
          <div className="row pl-3 pr-3 mt-3 text-dark pt-2 mb-2">
            <div className="col-md-12 h3 text-center text-capitalize">
              {typeof currentInfo !== "undefined" ? currentInfo.slug : null}
            </div>
          </div>
          <div className="row text-dark">
            <div className="col-12 col-lg-6 h4 text-center">
              {typeof currentInfo !== "undefined" &&
              currentInfo.featured_image !== null ? (
                <ImageLoader
                  alt={`featured`}
                  src={currentInfo.featured_image}
                ></ImageLoader>
              ) : null}
            </div>
            <div
              className="landing-header-right col-12 col-lg-6 align-middle"
              style={{ fontSize: "1.3rem" }}
            >
              {typeof currentInfo !== "undefined" ? paragraphs : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryLandingHeader;
