import axios from "axios";
import { GET_ERRORS } from "./types";
import { GET_ALL_GAMES, GET_GAME } from "./farkleTypes";

//get posts
export const getAllGames = postData => dispatch => {
	axios
		.get('/api/games')
		.then( res => {
			console.log(res.data)
			dispatch({
				type: GET_ALL_GAMES,
				payload: res.data,
				
			});
		})
		.catch(err => {
			dispatch({
				type: GET_ALL_GAMES,
				payload: null,
			})
		})
}


//create game
export const createGame = gameData => dispatch => {
	axios
		.post('/api/games', gameData)
		.then( res => {
			dispatch({
				type: GET_ALL_GAMES,
				payload: res.data,
			});
			dispatch({
				type: GET_ERRORS,
				payload: {}
			});
			document.location.href = "/games";
		})
		.catch(err => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		})
}

//get posts
export const getGame = id => dispatch => {
	axios
		.get('/api/games/'+id)
		.then( res => {
			console.log(res.data)
			dispatch({
				type: GET_GAME,
				payload: res.data,
				
			});
		})
		.catch(err => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data,
			})
		})
}

//get posts
export const editGame = id => dispatch => {
	axios
	.get('/api/games/'+id)
		.then( res => {
			dispatch({
				type: GET_ALL_GAMES,
				payload: res.data,
				
			});
		})
		.catch(err => {
			dispatch({
				type: GET_ALL_GAMES,
				payload: null,
			})
		})
}

//get posts
export const saveGame = postData => dispatch => {
	axios
		.get('/api/games')
		.then( res => {
			dispatch({
				type: GET_ALL_GAMES,
				payload: res.data,
				
			});
		})
		.catch(err => {
			dispatch({
				type: GET_ALL_GAMES,
				payload: null,
			})
		})
}

//create game
export const addUserToGame = (gameid, userid) => dispatch => {
	//dispatch(setPostLoading());
	axios
		.post('/api/games/addUser', {game: gameid, userid: userid})
		.then( res => {
			
			console.log(res)
			dispatch({
				type: GET_ALL_GAMES,
				payload: res.data,
			});
			dispatch({
				type: GET_ERRORS,
				payload: {}
			});
			document.location.href = "/games";
		})
		.catch(err => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		})
}




//editGame, getGame, saveGame