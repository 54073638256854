import React from "react";
import { Route, Switch } from "react-router-dom";
import { Component } from "react";
import PrivateRoute from "../components/common/PrivateRoute";
import CategoryPage from "../components/category-page/CategoryPage";
import Dashboard from "../components/dashboard/Dashboard";
import FarkleLanding from "../components/projects/games/farkle/FarkleLanding";
import NewGame from "../components/projects/games/farkle/NewGame";
import ShowAllGames from "../components/projects/games/farkle/ShowAllGames";
import ViewGame from "../components/projects/games/farkle/View/ViewGame";
import EditGame from "../components/projects/games/farkle/Edit/EditGame";
import AddFarkleFriends from "../components/projects/games/farkle/AddFarkleFriends";
import CategoryLanding from "../components/category-landing/CategoryLanding";

class ProjectsRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/projects" render={() => <CategoryPage />} />
        <Route exact path="/category/:category" component={CategoryPage} />
        <Route
          exact
          path="/category/landing/:category"
          component={CategoryLanding}
        />
        <Route exact path="/projects/games/farkle" component={FarkleLanding} />
        <Switch>
          <PrivateRoute exact path="/myprojects" component={Dashboard} />
          <PrivateRoute
            exact
            path="/projects/games/farkle/newGame"
            component={NewGame}
          />
          <PrivateRoute
            exact
            path="/projects/games/farkle/showAll"
            component={ShowAllGames}
          />
          <PrivateRoute
            exact
            path="/projects/games/farkle/edit/:id"
            component={EditGame}
          />
          <PrivateRoute
            exact
            path="/projects/games/farkle/view/:id"
            component={ViewGame}
          />
          <PrivateRoute
            exact
            path="/projects/games/farkle/addFriends"
            component={AddFarkleFriends}
          />
        </Switch>
      </React.Fragment>
    );
  }
}

export default ProjectsRoutes;
