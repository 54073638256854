import React from "react";
import { Route, Switch } from "react-router-dom";
import { Component } from "react";
import PrivateRoute from "../components/common/PrivateRoute";

import Dashboard from "../components/dashboard/Dashboard";
import CreateProfile from "../components/dashboard/CreateProfile/CreateProfile";
import EditProfile from "../components/dashboard/EditProfile/EditProfile";
import AddExperience from "../components/dashboard/AddMisc/AddExperience";
import AddEducation from "../components/dashboard/AddMisc/AddEducation";
import EditExperience from "../components/dashboard/EditExperience/EditExperience";
import EditEducation from "../components/dashboard/EditEducation/EditEducation";
import Register from "../components/auth/Register";
import Login from "../components/auth/Login";
import ChangeProfilePicture from "../components/uploader/ChangeProfilePicture";
import ChangePassword from "../components/dashboard/ChangePassword";
import ConfirmEmail from "../components/auth/ConfirmEmail";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";

class UserRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container user-pages">
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />

          <Switch>
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/change-password"
              component={ChangePassword}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/create-profile"
              component={CreateProfile}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/change-profile-picture"
              component={ChangeProfilePicture}
            />
          </Switch>
          <Switch>
            <PrivateRoute exact path="/edit-profile" component={EditProfile} />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/add-experience"
              component={AddExperience}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/add-education"
              component={AddEducation}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/edit-experience/:exp_id"
              component={EditExperience}
            />
          </Switch>
          <Switch>
            <PrivateRoute
              exact
              path="/edit-education/:edu_id"
              component={EditEducation}
            />
          </Switch>

          <Route exact path="/confirm/:email/:ccode" component={ConfirmEmail} />
          <Route
            exact
            path="/confirm-email/:email/:ccode"
            component={ConfirmEmail}
          />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route
            exact
            path="/reset-password/:email/:ccode"
            component={ResetPassword}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default UserRoutes;
