import React from "react";
import { Switch } from "react-router-dom";
import { Component } from "react";
import PrivateRoute from "../components/common/PrivateRoute";
import AdminHome from "../components/admin/AdminHome";

class AdminRoutes extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <PrivateRoute
            exact
            path="/admin"
            accessLevel="1"
            component={AdminHome}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/admin/:section"
            accessLevel="1"
            component={AdminHome}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/admin/:action/:dataType/:id"
            accessLevel="1"
            component={AdminHome}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/admin/:section/:dataType"
            accessLevel="1"
            component={AdminHome}
          ></PrivateRoute>
        </Switch>
      </React.Fragment>
    );
  }
}

export default AdminRoutes;
