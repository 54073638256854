import axios from 'axios';

export const getSectionData =  () => dispatch  => {
	
	const results = axios.get('/api/sections')
		.then(res => {
			console.log(res);
			this.state.results = res;
		})
		.catch(err => {
			
		})
		console.log(results)
		return results;
}