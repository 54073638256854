import React from "react";
import FullWidthContentCard from "../components/common/cards/FullWidthContentCard";
import ImageLoader from "../components/common/ImageLoader";
import { Link } from "react-router-dom";

export const parseContent = (item, index) => {
  let pindex = 0;
  const parser = (item) => {
    let key = Object.keys(item)[0];
    pindex++;
    switch (key) {
      case "row":
        if (!item.row[0].cols) {
          return parser();
        }
        return (
          <div key={"parser_" + index + pindex++} className="row h-100 mt-1">
            {item.row[0].cols.map((i) => {
              return parser(i);
            })}
          </div>
        );
      case "col":
        return (
          <div key={"parser_" + index + pindex++} className={`col-12 col-lg-6 p-3 pt-1 pb-1`}>
            {item.col.map((c) => {
              return parser(c);
            })}
          </div>
				);
			case "contentCard":
				let content = item.contentCard;
				return (
					<FullWidthContentCard key={"parser_" + index + pindex++} content={content} />
				)
      case "h1":
        return (
          <h1
            className="text-center mt-1 mb-2"
            key={"parser_" + index + pindex++}
          >
            <u>{item.h1}</u>
          </h1>
        );
      case "h2":
        return (
          <h2
            className="text-center mt-1 mb-2"
            key={"parser_" + index + pindex++}
          >
            <u>{item.h2}</u>
          </h2>
        );
      case "h3":
        return (
          <h3
            className="text-center mt-1 mb-2"
            key={"parser_" + index + pindex++}
          >
            <u>{item.h3}</u>
          </h3>
        );
      case "h4":
        return (
          <h4
            className="text-center mt-1 mb-2"
            key={"parser_" + index + pindex++}
          >
            <u>{item.h4}</u>
          </h4>
        );
      case "h5":
        return (
          <h5
            className="text-center mt-1 mb-2"
            key={"parser_" + index + pindex++}
          >
            <u>{item.h5}</u>
          </h5>
        );
      case "p":
				//item.p = item.p.replace(/(?:\r\n|\r|\n)/g, "<br>");
				// let paragraphs = item.p.split(/(?:\r\n|\r|\n)/g);
				// console.log("here",paragraphs);
        // return (
        //   <p
        //     key={"parser_" + index + pindex++}
        //     className={"m-1 mb-3 mt-2"}
        //     dangerouslySetInnerHTML={{ __html: item.p }}
				//   ></p>
				
				return(
					<p
            key={"parser_" + index + pindex++}
            className={"m-1 mb-3 mt-2"}
					>
						{item.p}
					</p>
				);
				
			case "a":
				if(item.a.newtab)
				{
					return(
						<a href={item.a.url} 
							rel="noopener noreferrer"
							target="blank"
						>
							{item.a.txt}
						</a>
					)
				}
				else{
					return (
						<a href={item.a.url}>{item.a.txt}</a>
					)
				}
			case "link":
				return (
					<Link href={item.a.url}>{item.link.txt}</Link>
				)
      case "img":
			let style = {};
			if(item.img.valign === "true")
				{
					style = {
						position: "relative",
						top: "50%",
						transform: "translateY(-50%)"
					}
				}
        return (
					<div key={"parser_" + index + pindex++}
						style={style}>
						<ImageLoader
							src={item.img.src}
							size={item.img.size ? item.img.size : null}
							alt={item.img.alt ? item.img.alt : null}
						/>
					</div>
        );

      case "ul":
        let lis = item.ul.map((li, index) => {
          return <li key={"parser_" + index + pindex++}>{li.txt}</li>;
        });
        return <ul className="mb-0" key={"parser_" + index + pindex++}>{lis}</ul>;

      default:
        return null;
    }
  };


  return parser(item);
};
