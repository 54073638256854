import React, { Component } from "react"; 
import {Link} from "react-router-dom";
import axios from "axios";
import Spinner from "../../common/Spinner";
import ImageLoader from "../../common/ImageLoader";

class ContentTable extends Component {
	constructor(props){
		super(props);
		this.state = {
			errors: {},
			data: [],
		}

		this.deleteData = this.deleteData.bind(this);
	}

	deleteData(e){
		if(!window.confirm('Are you sure?  This cannot be undone!')) {
			return;
		}

		let val = e.target.getAttribute('value');
		let arr;
		if(this.state.data.length === 0)
		{
			this.setState({data: this.props.data})
			arr = this.props.data
		}
		else{
			arr = this.state.data;
		}
		
		axios.post("/api/content/delete", {id: val})
			.then(res => {
				let data = arr.filter(dt => {
					return dt.id !== val*1
				});
				this.setState({
					data: data
				})
			})
			.catch(err => console.warn(err));
		
	}

	render() {
		let data;
		if(this.state.data.length > 0)
		{
			data = this.state.data;
		}
		else{
			data = this.props.data;
		} 
		
		if(data.length === 0){
			return (<Spinner/>);
		}

		let displayData = data.map( (content, i) => {
			let bg2 = ( (i%2 === 0) ? "#ececec" : '' )
			return (
				<tr key={i} className={`p-1 m-1`}  style={{ borderBottom: "1px solid #000", background: bg2 }}>
					<td className="text-center">{content.id}</td>
					<td className="text-center pt-1 pb-1" >
						<Link to={`/admin/edit/content/${content.id}`} >
							<ImageLoader alt="featured" src={content.featured_image ? content.featured_image : "/images/icons/nocontent.jpg"} className="p-1" height="85px" />
						</Link>	
					</td>
					<td className="text-capitalize">
						<i onClick={this.deleteData} value={content.id} className="delete-user text-danger fa fa-window-close mr-2"></i>
						<Link to={`/admin/edit/content/${content.id}`} >{content.title.replace(/_/g, ' ')}</Link>
					</td>
					<td>{content.slug}</td>
					<td>{content.tags}</td>
				</tr>
			)
		})

		return (
			<div>
				<div className="row text-center">
					<div className="col">
						{data.length > 0 ? null : <div className="text-danger h4 text-center mt-4 p-3">No contents match your search</div> }
					</div>
				</div>
				<table className="w-100">
					<thead>
						<tr>
							<td className="h5 p-1 text-center"><u>ID</u></td>
							<td className="h5 p-1 text-center"><u>Featured Image</u></td>
							<td className="h5"><u>Title</u></td>
							<td className="h5"><u>Slug</u></td>
							<td className="h5"><u>Tags</u></td>
						</tr>
					</thead>
					<tbody>
						{data.length > 0 ? displayData : null}
					</tbody>
				</table>	
			</div>
		);
	}
}

export default ContentTable;