import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentProfile, deleteAccount } from "../../actions/profileActions";
import Spinner from "../common/Spinner";
import { Link } from "react-router-dom";
import ProfileActions from "./ProfileActions";
import Experience from "./Experience";
import Education from "./Education";
import isEmpty from "../../validation/is-empty";
import {
  changeAvatar,
  changeName,
  refreshToken,
} from "../../actions/authActions";
import ImageLoader from "../common/ImageLoader";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeName: false,
      name: "",
    };
    this.toggleNameEdit = this.toggleNameEdit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    this.props.getCurrentProfile();
    if (localStorage.avatar) {
      this.props.changeAvatar(localStorage.avatar);
      //localStorage.removeItem("avatar");
    }
    this.setState({ name: this.props.auth.user.name });
  }

  onDeleteClick(e) {
    this.props.deleteAccount();
  }

  toggleNameEdit(e) {
    if (this.state.changeName === true) {
      this.props.changeName(this.state.name);
      // this.props.refreshToken();
    } else {
      //this.setState({name: this.props.auth.user.name});
    }
    this.setState({ changeName: !this.state.changeName });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    const { user } = this.props.auth;
    const { profile, loading } = this.props.profile;
    let skills = [];
    let dashboardContent;

    if (profile == null || loading) {
      dashboardContent = <Spinner />;
    } else {
      if (profile.skills) {
        skills = profile.skills.map((skill, index) => (
          <div
            key={index}
            className="card p-1 pl-2 pr-2 d-inline-block bg-dark text-light mr-1 mb-1"
          >
            <i className="fa fa-check  mr-1"></i>
            {skill}
          </div>
        ));
      }
      // check if logged in user has profile data

      if (Object.keys(profile).length > 0) {
        let bio = profile.bio.replace(/•/g, "").split(/(?:\r\n|\r|\n)/g);
        bio = bio.map((line, i) => (
          <p key={i} className="ml-1">
            {line}
          </p>
        ));
        dashboardContent = (
          <div id="dashboard">
            <div className="row">
              <div className="col col-12 col-md-4 col-lg-3">
                <h4
                  className="text-muted align-middle text-center  mb-2"
                  style={{ margin: "0 auto" }}
                >
                  Welcome&nbsp;
                  {!this.state.changeName ? (
                    <Fragment>
                      <div>
                        <Link to={`/social/profiles/${profile.handle}`}>
                          {this.state.name}
                        </Link>
                      </div>
                      <div
                        className="btn btn-info mt-2"
                        onClick={this.toggleNameEdit}
                      >
                        Change Name
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div>
                        <input
                          name="name"
                          onChange={this.onChange}
                          value={this.state.name}
                        ></input>
                      </div>
                      <div
                        className="text-center btn btn-info mt-2"
                        onClick={this.toggleNameEdit}
                      >
                        Save Name
                      </div>
                    </Fragment>
                  )}
                </h4>
                <Link to="/change-profile-picture" className="profile-avatar">
                  <ImageLoader
                    className=" p-2 p-lg-1"
                    src={profile.user.avatar}
                    alt=""
                  />
                </Link>
                <div className="text-center profile-actions mt-lg-2">
                  <ProfileActions />
                </div>
              </div>

              <div className="profile-info col col-12 col-md-8 col-lg-9">
                <h2 className="text-center">
                  <u>Your Current Profile</u>
                </h2>
                <div className="profile-items">
                  <div className="profile-item">
                    <span className="profile-item-label font-weight-bold d-block d-lg-inline-block d-block d-lg-inline-block">
                      Email:{" "}
                    </span>
                    <span className="profile-item-value">{user.email}</span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-item-label font-weight-bold d-block d-lg-inline-block d-block d-lg-inline-block">
                      Handle:{" "}
                    </span>
                    <span className="profile-item-value">{profile.handle}</span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-item-label font-weight-bold d-block d-lg-inline-block">
                      Status:{" "}
                    </span>
                    <span className="profile-item-value">{profile.status}</span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-item-label font-weight-bold d-block d-lg-inline-block">
                      Company:{" "}
                    </span>
                    <span className="profile-item-value">
                      {profile.company}
                    </span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-item-label font-weight-bold d-block d-lg-inline-block">
                      Website:{" "}
                    </span>
                    <span className="profile-item-value">
                      {profile.website}
                    </span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-item-label font-weight-bold d-block d-lg-inline-block">
                      Your Location:{" "}
                    </span>
                    <span className="profile-item-value">
                      {profile.location}
                    </span>
                  </div>
                  <div className="profile-item skills pb-2">
                    <div
                      className="profile-item-label font-weight-bold d-block mb-1"
                      style={{ display: "block !important" }}
                    >
                      Skills:{" "}
                    </div>
                    {skills}
                  </div>
                  <div className="profile-item">
                    <span className="profile-item-label font-weight-bold d-block d-lg-inline-block">
                      Github Username:{" "}
                    </span>
                    <span className="profile-item-value">
                      {profile.githubusername}
                    </span>
                  </div>
                  <div className="profile-item">
                    <span className="profile-item-label font-weight-bold d-block mb-1">
                      Your Bio:{" "}
                    </span>
                    <span className="profile-item-value">{bio}</span>
                  </div>
                </div>
                <div className="row mt-3">
                  <Experience experience={profile.experience} />
                  <Education education={profile.education} />
                </div>
                <div className="row p-1">
                  {isEmpty(profile.website) ? null : (
                    <div>
                      <a
                        className="btn btn-primary text-white p-2 mr-1"
                        href={profile.website}
                      >
                        <i className="fa fa-globe mr-3 fa-1x"></i>Website
                      </a>
                    </div>
                  )}

                  {isEmpty(profile.social && profile.social.twitter) ? null : (
                    <div>
                      <a
                        className="btn btn-primary text-white p-2 mr-1"
                        href={profile.social.twitter}
                      >
                        <i className="fa fa-twitter mr-3 fa-1x"></i>Twitter Home
                      </a>
                    </div>
                  )}

                  {isEmpty(profile.social && profile.social.facebook) ? null : (
                    <div>
                      <a
                        className="btn btn-primary text-white p-2 mr-1"
                        href={profile.social.facebook}
                      >
                        <i className="fa fa-facebook mr-3 fa-1x"></i>Facebook
                        Profile
                      </a>
                    </div>
                  )}
                  {isEmpty(profile.social && profile.social.linkedin) ? null : (
                    <div>
                      <a
                        className="btn btn-primary text-white p-2 mr-1"
                        href={profile.social.linkedin}
                      >
                        <i className="fa fa-linkedin mr-3 fa-1x"></i>LinkedIn
                        Profile
                      </a>
                    </div>
                  )}
                  {isEmpty(
                    profile.social && profile.social.instagram
                  ) ? null : (
                    <div>
                      <a
                        className="btn btn-primary text-white p-2 mr-1"
                        href={profile.social.instagram}
                      >
                        <i className="fa fa-instagram mr-3 fa-1x"></i>Instagram
                        Home
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        dashboardContent = (
          <div>
            <p className="lead text-muted">Welcome {user.name}</p>
            <p>You have not yet set up your profile, please add some info</p>
            <Link to="/create-profile" className="btn btn-lg btn-info">
              Create Profile
            </Link>
          </div>
        );
      }
    }
    return (
      <div className="dashboard">
        <div className="">
          <div className="row">
            <div className="col-md-12 ">{dashboardContent}</div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  deleteAccount: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  deleteAccount,
  changeAvatar,
  changeName,
  refreshToken,
})(Dashboard);
