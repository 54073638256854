import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../common/Spinner";
import { getProfiles } from "../../../actions/profileActions";
import ProfileItem from "./ProfileItem";
import TextFieldGroup from "../../common/TextFieldGroup";
import axios from "axios";
import DockerNews from "../../common/modules/DockerNews";
import RedditModule from "../../common/modules/Reddit";

class Profiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      users: [],
      sprofiles: [],
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    this.props.getProfiles();
    this.setState({ profiles: this.props.profile });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.profiles !== this.state.profiles &&
      this.state.profiles &&
      this.state.profiles.length > 0
    ) {
      return true;
    }
    return false;
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value.length >= 3) {
      axios
        .post("/api/profile/find", { search: e.target.value })
        .then((profiles) => {
          this.setState({ profiles: profiles.data });
        })
        .catch((err) => console.warn(err));
    } else {
      axios
        .get("/api/users")
        .then((profiles) => {
          this.setState({ sprofiles: profiles.data });
        })
        .catch((err) => console.warn(err));
    }
  }

  render() {
    let { profiles, loading } = this.props.profile;
    if (this.state.search && this.state.search.length >= 3) {
      profiles = this.state.profiles;
    }

    let profileItems = "";
    if (profiles === null || loading === true) {
      profileItems = <Spinner />;
    } else {
      if (profiles.length > 0) {
        profileItems = profiles.map((profile) => {
          return <ProfileItem key={profile._id} profile={profile} />;
        });
      } else {
        profileItems = <h4>No Profiles Found</h4>;
      }
    }
    return (
      <div className="profiles mt-2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1
                className="display-4 text-center"
                style={{ marginTop: "-20px" }}
              >
                User Profiles
              </h1>
              <div className="col-md-8 m-auto">
                <p
                  className="lead text-center pb-1"
                  style={{ marginBottom: "0px" }}
                >
                  Browse and connect with other LukeDev.net users
                </p>
                <form onSubmit={this.onSubmit}>
                  {this.state.errors.apifailure && (
                    <div className="text-danger">
                      Connection to Login API failed Status:{" "}
                      {this.state.errors.text}
                    </div>
                  )}
                  <TextFieldGroup
                    name="search"
                    type="text"
                    value={this.state.search}
                    onChange={this.onChange}
                    error={this.state.errors.search}
                    placeholder="find user by name or LukeDev handle"
                  />
                </form>
              </div>
							<div className="row">
								<div className="col col-12 col-lg-8 bg-light text-dark mt-3 pt-3">
								{profileItems}
								</div>
								<div className="col col-12 col-lg-4 mt-3 bg-light text-dark pt-2">
									
									<iframe
										scrolling="no"
										title="news-widget"
										id="widget"
										className="card mb-2"
										src="/public/widget.html?gfd=71"
										style={{ width: "100%", scroll: "none" }}
									></iframe>
									<RedditModule stream="linux"/>
									<DockerNews />
								

								</div>
							</div>
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Profiles.propTypes = {
  getProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getProfiles })(Profiles);
