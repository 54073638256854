import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { deletePost, likePost, unlikePost } from "../../../actions/postActions";

class PostItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      likes: [],
    };
  }
  componentDidMount() {
    this.setState({ likes: this.props.post.likes });
  }
  onDeleteClick(id) {
    this.props.deletePost(id);
  }
  onLikeClick(id) {
    this.props.likePost(id);
    if (
      this.findUserLike(this.state.likes, this.props.auth.user.id) === false
    ) {
      var obj = this.state.likes;
      var tmpobj = {
        _id: "55555555",
        user: this.props.auth.user.id,
      };

      obj.push(tmpobj);
      this.setState({ likes: obj });
    }
  }

  onUnlikeClick(id) {
    this.setState({
      likes: this.state.likes.filter((like) => {
        return like.user !== this.props.auth.user.id;
      }),
    });
  }

  findUserLike(likes, pid) {
    const { auth } = this.props;
    if (likes.filter((like) => like.user === auth.user.id).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.post) {
      const post = this.props.post;
      if (post.likes) {
        if (post.likes.length !== nextProps.post.likes.length) {
          return true;
        }
      } else {
        return false;
      }
    }
    return true;
  }

  render() {
    const { post, auth, showActions } = this.props;

    let deleteButton;
    if (auth.user.id === post.user) {
      deleteButton = (
        <button
          onClick={this.onDeleteClick.bind(this, post._id)}
          type="button"
          className="btn btn-danger mr-1"
        >
          <i className="fa fa-times" /> Delete
        </button>
      );
    }
    return (
      <div className="card card-body mb-3">
        <div className="row">
          <div className="col-md-2">
            <a href={`/post/${post._id}`}>
              <img
                className="rounded-circle d-none d-md-block"
                src={post.avatar}
                alt={post.name}
              />
            </a>
            <br />
            <Link to={`/post/${post._id}`} className="text-center">
              {post.name}
            </Link>
          </div>
          <div className="col-md-10">
            <p className="lead">{post.text}</p>
            {showActions ? (
              <span>
                <button
                  onClick={this.onLikeClick.bind(this, post._id)}
                  type="button"
                  className="btn btn-light mr-1"
                >
                  <i
                    className={classnames("fa fa-thumbs-up", {
                      "text-info": this.findUserLike(this.state.likes),
                    })}
                  ></i>
                  <span className="badge badge-light">
                    {this.state.likes.length}
                  </span>
                </button>
                <button
                  onClick={this.onUnlikeClick.bind(this, post._id)}
                  type="button"
                  className="btn btn-light mr-1"
                >
                  <i
                    className={classnames("text-secondary fa fa-thumbs-down")}
                  ></i>
                </button>
                <Link to={`/post/${post._id}`} className="btn btn-info mr-1">
                  Comments
                </Link>
              </span>
            ) : null}
            {deleteButton}
            {/*<!-- <button type="button" class="btn btn-danger mr-1">
<i class="fa fa-times" />
						</button> -->*/}
          </div>
        </div>
      </div>
    );
  }
}

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deletePost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deletePost, likePost, unlikePost })(
  PostItem
);
