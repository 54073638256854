import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { getSectionData } from "../../actions/sectionActions";
import PageHeader from "../common/PageHeader";
import { parseContent } from "../../utils/contentParser";
import ProjectsModule from "../common/ProjectsModule";
import RedditModule from "../common/modules/Reddit";

class ContentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: {},
      currentInfo: "",
    };
  }

  getContent() {
    axios
      .get("/api/content/" + this.props.match.params.contentId)
      .then((res) => {
        if (res) {
          this.setState({ currentInfo: res.data });
        }
      })
      .catch((err) => {
        console.warn({ err: err });
      });
    axios.get("/api/sections/category/" + this.props.match.params.category);
  }

  componentDidMount() {
    this.getContent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.getContent();
    }
  }

  render() {
    // Set info variable for current category
    let currentInfo;
    let paragraphs;
    let text_paragraphs;
    if (typeof this.state.currentInfo.id !== "undefined") {
      currentInfo = this.state.currentInfo;
      if (typeof currentInfo !== "undefined") {
        let contentItems = JSON.parse(currentInfo.description);
        paragraphs = contentItems.map((item, index) => {
          return parseContent(item, index);
        });
      }

      if (typeof currentInfo !== "undefined") {
        if (currentInfo.text !== null) {
          let currentItems = JSON.parse(currentInfo.text);
          text_paragraphs = currentItems.map((item, index) => {
            return parseContent(item, index);
          });
        }
      }
    }

    // FINAL RENDER
    return (
      <div className="landing category-landing">
        <div className="dark-overlay text-light pb-2">
          {/*--------------Title Bar ----------------*/}
          <div className="container mt-3 mb-1">
            <div className="row">
              <div className="col-md-12 text-center">
                <div
                  onClick={this.props.history.goBack}
                  className="btn btn-secondary btn-md mt-3 float-left"
                  style={{ position: "relative", top: "3px" }}
                >
                  &#x2B05; Back
                </div>
                <div className="h2 mt-3 text-capitalize ">
                  {typeof currentInfo !== "undefined"
                    ? currentInfo.title.replace(/_/g, " ")
                    : null}
                </div>
              </div>
            </div>
          </div>

          {/*----------------Header --------------------*/}
          <PageHeader currentInfo={currentInfo} />
          {/*--------------END HEADER-------------------*/}

          <div className="container bg-white text-dark p-3">
            <div className="row">
              <div className="col col-12 col-lg-8">
                <div className="h4 text-center">
                  {typeof currentInfo !== "undefined" ? paragraphs : null}
                </div>
                {typeof currentInfo !== "undefined" ? text_paragraphs : null}
              </div>
              <div className="col col-12 col-lg-4">
                <iframe
                  scrolling="no"
                  title="news-widget"
                  id="widget"
                  className="card mb-2"
                  src="/public/widget.html?gfd=77"
                  style={{ width: "100%", scroll: "none" }}
                ></iframe>
                <ProjectsModule />
                <RedditModule stream="linux" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ContentPage.propTypes = {
  auth: PropTypes.object.isRequired,
  getSectionData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { getSectionData })(ContentPage);
