import React, { Component } from "react"; 
import "./admin.css";
import AdminNav from "./AdminNav";
import ManageUsers from "./Users/ManageUsers";
import ManageSections from "./Sections/ManageSections";
import ManageContent from "./Content/ManageContent";
import EditSection from "./Sections/EditSection";
import EditContent from "./Content/EditContent";
import ManageAssets from "./Assets/ManageAssets";
import AddAssets from "./Assets/AddAssets";
import ManageData from "./ManageData/ManageData";
import NewContent from "./Content/NewContent";
import NewSection from "./Sections/NewSection";
import EditAsset from "./Assets/EditAsset";
import AdminWelcome from "./AdminWelcome";
class AdminHome extends Component {
	render() {
		let adminContent;
		
		switch(this.props.match.params.section)
		{
			case "users":
				adminContent = (<ManageUsers />)
				break;
			case "sections":
				adminContent = <ManageSections />
				break;
			case "edit-section":
				adminContent = <EditSection sid={this.props.match.params.id} />
				break;
			case "edit-content":
				adminContent = <EditContent sid={this.props.match.params.id} />
				break;
			case "add-assets":
				adminContent = <AddAssets />
				break;
			case "assets":
					adminContent = <ManageAssets />
					break;			
			case "content":
				adminContent = <ManageContent />
				break;
			case "manage-data":
				adminContent = <ManageData dataType={this.props.match.params.dataType}/>;
				break;
			default:
				adminContent = <AdminWelcome/>
		}
		switch(this.props.match.params.action){
			case "edit":
				if(this.props.match.params.dataType === "content")
				{
					adminContent = <EditContent dataType={this.props.match.params.dataType} sid={this.props.match.params.id} />
				}
				if(this.props.match.params.dataType === "section")
				{
					adminContent = <EditSection dataType={this.props.match.params.dataType} sid={this.props.match.params.id} />
				}
				if(this.props.match.params.dataType === "asset")
				{
					adminContent = <EditAsset dataType={this.props.match.params.dataType} sid={this.props.match.params.id} />
				}
				break;
			case "new":
				if(this.props.match.params.dataType === "content")
				{
					adminContent = <NewContent dataType={this.props.match.params.dataType}/>
				}
				if(this.props.match.params.dataType === "sections")
				{
					adminContent = <NewSection dataType={this.props.match.params.dataType} sid={this.props.match.params.id} />
				}
				if(this.props.match.params.dataType === "assets")
				{
					adminContent = <AddAssets dataType={this.props.match.params.dataType} sid={this.props.match.params.id} />
				}
				break;
			default:
				

		}
		
		return (
			<div id="admin-page" className="landing" style={{ marginTop: "-25px" }}>
				<div className="dark-overlay text-light pb-0">
					<div className="container mb-1" >
						<div className="row">
							<div className="col-md-12 text-center">
								<div className="row">
									<div className="col col-4 col-sm-3 col-md-3 col-lg-2"></div>
									<div className="col col-8 col-xs-6 col-sm-9 col-md-9 col-lg-10 h2 mt-2 " style={{ paddingLeft: "95px" }}>
										Admin Home
									</div>
								</div>
							</div>
						</div>
						
					</div>
					<div className="bg-light text-dark" style={{ width: "100%" }}>
						<div className=" ">
						
							<div className="row" style={{ marginLeft: "0px", marginRight: "0px" }}>
								<div className="col col-4 col-sm-3 col-md-3 col-lg-2" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
									<AdminNav />
								</div>

								<div className="col col-8 col-xs-6 col-sm-9 col-md-9 col-lg-10 pb-3">
									{adminContent}
								</div>
							</div>
						</div>
					</div>
				</div>
		</div>
	);
	}
}

export default AdminHome;