import axios from 'axios';

import { GET_PROFILE, PROFILE_LOADING, CLEAR_CURRENT_PROFILE, GET_PROFILES} from './profileTypes';
import { GET_ERRORS, SET_CURRENT_USER  } from './types';

// Get the current profile
export const getCurrentProfile = () => dispatch => {
	dispatch(setProfileLoading());
	axios.get('/api/profile')
		.then(res => {
			dispatch({
				type: GET_PROFILE,
				payload: res.data
			})
		})
		.catch(err => {
			dispatch({
				type: GET_PROFILE,
				payload: {}
			})
		})
}

// Get profile by handle
export const getProfileByHandle = (handle) => dispatch => {
	dispatch(setProfileLoading());
	axios.get(`/api/profile/handle/${handle}`)
		.then(res => {
			dispatch({
				type: GET_PROFILE,
				payload: res.data
			})
		})
		.catch(err => {
			dispatch({
				type: GET_PROFILE,
				payload: {}
			})
		})
}

//get all profiles
export const getProfiles = () => dispatch => {
	dispatch(setProfileLoading());
	axios.get('/api/profile/all')
		.then(res => {
			dispatch({
				type: GET_PROFILES,
				payload: res.data
			})
		})
		.catch(err => {
			dispatch({
				type: GET_PROFILES,
				payload: null
			})
		})
}

//search profiles
export const searchProfiles = (searchText) => dispatch => {
	dispatch(setProfileLoading());
	axios.get('/api/profile/all')
		.then(res => {
			dispatch({
				type: GET_PROFILES,
				payload: res.data
			})
		})
		.catch(err => {
			dispatch({
				type: GET_PROFILES,
				payload: null
			})
		})
}

// create Profile
export const createProfile = (profileData, history) => dispatch => {
	axios
		.post('/api/profile', profileData)
		.then(res => {
			history.push('/dashboard')
		})
		.catch(err => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		})
}

// Profile loading
export const setProfileLoading = () => {
	return {
		type: PROFILE_LOADING
	}
}

// Clear Profile
export const clearProfile = () => {
	return {
		type: CLEAR_CURRENT_PROFILE
	}
}

// Add Experience
export const addExperience = (expData, history, deleteFirst) => dispatch => {
	if(deleteFirst){
		let id=expData._id;
		axios
			.delete(`/api/profile/experience/${id}`)
			.then(res => {
				axios
					.post('/api/profile/experience', expData)
					.then(res => {
							history.push("/dashboard");
						}
					)
					.catch(err => {
						dispatch({
							type: GET_ERRORS,
							payload: err.response.data
						})
					});

					
				}
			)
			.catch(err => {
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
			});
	}
	else{
		axios
		.post('/api/profile/experience', expData)
		.then(res => {
			history.push("/dashboard");
			}
		)
		.catch(err => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		});
	}
}

// Add Experience
export const addEducation = (eduData, history, deleteFirst) => dispatch => {
	if(deleteFirst){
		let id=eduData._id;
		axios
			.delete(`/api/profile/education/${id}`)
			.then(res => {
				axios
					.post('/api/profile/education', eduData)
					.then(res => {
							history.push("/dashboard");
						}
					)
					.catch(err => {
						dispatch({
							type: GET_ERRORS,
							payload: err.response.data
						})
					});

					
				}
			)
			.catch(err => {
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
			});
	}
	else{
		axios
		.post('/api/profile/education', eduData)
		.then(res => {
			history.push("/dashboard");
			}
		)
		.catch(err => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		});
	}
}

// Delete experience with id = id
export const deleteExperience = (id, force) => dispatch => {
	if(force === true){ 
		axios
			.delete(`/api/profile/experience/${id}`)
			.then(res => {
					dispatch({
						type: GET_PROFILE,
						payload: res.data
					})
				}
			)
			.catch(err => {
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
			});
	}
	else {
		if(window.confirm('Are you sure you want to delete this experience?  This cannot be undone!')) {
			axios
			.delete(`/api/profile/experience/${id}`)
			.then(res => {
					dispatch({
						type: GET_PROFILE,
						payload: res.data
					})
				}
			)
			.catch(err => {
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data
				})
			});
		}
	}
}

// Delete education with id = id
export const deleteEducation = (id) => dispatch => {
	axios
		.delete(`/api/profile/education/${id}`)
		.then(res => {
				dispatch({
					type: GET_PROFILE,
					payload: res.data
				})
			}
		)
		.catch(err => {
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		});
}

// Delete account & profile
export const deleteAccount = () => dispatch => {
	if(window.confirm('Are you sure?  This cannot be undone!')) {
		axios
			.delete('/api/profile')
			.then(res => {
				dispatch({
					type: SET_CURRENT_USER,
					payload: {},
				});
			})
			.catch(err => {
				dispatch({
					type: GET_ERRORS,
					payload: err.response.data,
				})
			});
	}
};
